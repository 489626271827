<template>
  <div class="project">
    <div>
      <p class="w3-hover-yellow" style="margin:1px" @click="showDetails = !showDetails">{{ doc.title }}</p>
    </div>
  </div>
  <div v-if="showDetails">
    <DisplayContents :doc="doc" :clients="clients" @update="update">
    </DisplayContents>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import DisplayContents from "@/views/doc_management/display_doc/DisplayContents.vue";
export default {
  props: ["doc", "clients",],
  components: { DisplayContents },
  setup(props, ctx) {
    //setup(props, context) {
    //const can_slots = computed(() => props.machine.can_slots.split("/"));
    //const coeffee_slots = computed(() => props.machine.coeffee_slots.split("/"));

    const showDetails = ref(false);

    function update(updated_machine) {
      //console.log("single machine");
      //console.log(updated_machine);
      ctx.emit("update", updated_machine);
    }

    return {
      showDetails,

      update,
    };
  },

  /*data() {
          return {
              showDetails: false
          }
      },
      methods: {
          toggleShowDetails() {
              this.showDetails = !this.showDetails
          },
          deleteProject() {
              const body = {'action': 'delete', 'body': {id: this.project.id}}
              const option = {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
              fetch('delete_a_project.php', option)
                  .then(() => this.$emit('delete', this.project.id))
                  .catch(err => console.log(err.message))
          },
          editProject() {
              const body = {'action': 'edit', 'body': {id: this.project.id}}
              const option = {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
              fetch('get_a_project.php', option)
              .then( res => res.json())
                  .then(data => console.log(data))
                  .catch(err => console.log(err.message))
          },
          toggleComplete() {
              // 0 == false  1 == true
              let temp_complete = 0;
              if (this.project.complete) temp_complete = 1;
              const body = {'action': 'update', 'body': {id: this.project.id, complete: temp_complete}}
              const option = {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
              fetch('update_complete_status.php', option)
                  .then(() => this.$emit('toggle_complete', this.project.id))
                  .catch(err => console.log(err.message))
  
          }
      }*/
};
</script>

<style scoped>
.project {
  /*margin: 20px auto;*/
  margin: 2px 2px 2px 2px;
  background: white;
  /*padding: 10px 20px;*/
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  border-left: 4px solid #e90074;
}

.project.complete {
  border-left: 4px solid #00ce89;
}

.project.complete .tick {
  color: #00ce89;
}
</style>