<template>
  <div class="my-w3-container">
    <div class="w3-bar w3-teal">
      <button class="w3-bar-item w3-button" @click="new_menu('재료')">재료</button>
      <button class="w3-bar-item w3-button" @click="new_menu('기계')">기계</button>
<!--      <button class="w3-bar-item w3-button" @click="new_menu('거래처')">거래처</button>-->
      <button class="w3-bar-item w3-button" @click="new_menu('카운타')">카운타</button>
      <button class="w3-bar-item w3-button" @click="new_menu('상품')">상품</button>
      <button class="w3-bar-item w3-button" @click="new_menu('문서')">문서</button>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  setup(props, ctx) {
    const menu_selection = ref("");

    function new_menu(new_menu) {
      let message = { new_menu: new_menu }
      ctx.emit("message", message)
    }

    return {
      menu_selection,
      new_menu
    }
  }
}
</script>
