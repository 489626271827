<template>
  <div class="my-w3-container">
    <div class="w3-container w3-green">
      <div class="w3-row">
        <h2 class="w3-col s10">카운타</h2>
      </div>
    </div>
  </div>

  <div class="my-w3-container">

    <!-- 거래처이름으로 서치 -->
    <SelectDate />

    <button @click="open_cal_counter = true" class="btn danger">
      2구간카운타계산
    </button>

    <div v-if="open_cal_counter">
      <CounterModal @message="message_handler" @close="open_cal_counter = false"></CounterModal>
    </div>

    <div v-if="data_to_print.length">
      <div v-for="data in data_to_print" :key="counters">
        <div v-for="machine in data.data" :key="machine">
          {{ machine.machine }}
          <div class="grid_container_for_counter1">
            <div v-for="element in machine.data" :key="element">
              <p>{{ element.myvalue }}</p>
            </div>
          </div>
          <div class="grid_container_for_counter2">
            <div v-for="element in machine.data" :key="element">
              <p>{{ element.start }}, {{ element.end }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import SelectDate from "./SelectDate.vue";
import CounterModal from "./CounterModal.vue";
//import DisplayCounter from "./DisplayCounter.vue";

import { ref, computed } from "vue";

export default {
  components: {
    SelectDate, CounterModal, //DisplayCounter
  },
  setup() {
    const open_cal_counter = ref(false)

    const my_clients = computed(() => {
      return my_storage.get("clients")
    })

    const data_to_print = ref([])

    // {client: "평택단자", name:"1. 물류커피"}
    const my_machines = computed(() => {
      let machines = my_storage.get("machines");
      //console.log('machines in counternote.vue')
      //console.log(machines)
      //console.log('my_clients in counternote.veu')
      //console.log(my_clients.value)
      let i, myarray = [], temp
      for (i = 0; i < my_clients.value.length; i++) {
        temp = machines.filter((machine) => machine.client == my_clients.value[i].name)
        //console.log('temp')
        //console.log(temp)
        myarray.push({ client: my_clients.value[i].name, machines: temp })
      }
      return myarray
    })

    function calculate_sum(counters, client, machine, startday, endday) {
      console.log('in calculate_sum')
      console.log('client=' + client)
      console.log('machine=' + machine)
      let startcounter = counters.filter((counter) => counter.client == client && counter.name == machine && counter.counter_date == startday)
      console.log('startcounter in calculate_sum in ocunter note vue')
      console.log(startcounter)
      if (startcounter.length == 0)
        return { client: client, machine: machine, startday: startday, endday: endday, start: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], end: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], sum: 0 }

      let endcounter = counters.filter((counter) => counter.client == client && counter.name == machine && counter.counter_date == endday)
      console.log('endcounter in calculate_sum in ocunter note vue')
      console.log(endcounter)
      if (endcounter.length == 0)
        return { client: client, machine: machine, startday: startday, endday: endday, start: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], end: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], sum: 0 }
      let startarray = JSON.parse(startcounter[0].counter)
      let endarray = JSON.parse(endcounter[0].counter)
      let my_value = [200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900, 2000]
      console.log('start=' + startarray)
      console.log('end=' + endarray)
      let i, sum = 0
      for (i = 0; i < 18; i++) {
        sum = sum + (my_value[i] * (endarray[i] - startarray[i]))
      }
      console.log('sum=' + sum)
      return { client: client, machine: machine, startday: startday, endday: endday, start: startarray, end: endarray, sum: sum }
    }

    function message_handler(message) {
      console.log('message handler in counternote.vue')
      console.log(message)
      // counters에서 counter_date=day1 or counter_date=day2인 record를 모은다.
      let counters = my_storage.get("counters")
      console.log('counters')
      console.log(counters)
      console.log('my_machines')
      console.log(my_machines.value)
      console.log(my_machines.value[0].client)
      console.log(my_machines.value[0].machines[0].name)
      //let filteredCounters_start, filteredCounters_end
      //filteredCounters_start = counters.filter((counter)=>counter.counter_date==message.params.day1)
      //filteredCounters_end = counters.filter((counter)=>counter.counter_date==message.params.day2)

      // my_machines
      let i, j, sumarray = [], clientsumarray = [], oldclient = "", sum
      for (i = 0; i < my_machines.value.length; i++) {
        sumarray = []
        for (j = 0; j < my_machines.value[i].machines.length; j++) {
          //                      client                       machine name                      daystart             dayend
          sum = calculate_sum(counters, my_machines.value[i].client, my_machines.value[i].machines[j].name, message.params.day1, message.params.day2)
          sumarray.push(sum)

          console.log('client=' + my_machines.value[i].client + ', machine=' + my_machines.value[i].machines[j].name + ', start=' + message.params.day1 + ', end=' + message.params.day2 + ', sum=' + sum)
        }
        clientsumarray.push({ client: sum.client, startday: sum.startday, endday: sum.endday, data: sumarray })
      }
      console.log('final')
      console.log(clientsumarray)
      for (i = 0; i < clientsumarray.length; i++) {
        for (j = 0; j < clientsumarray[i].data.length; j++) {
          let temp = remove_zero(clientsumarray[i].data[j])
          clientsumarray[i].data[j] = { machine: clientsumarray[i].data[j].machine, sum: clientsumarray[i].data[j].sum, data: remove_zero(clientsumarray[i].data[j]) }
        }
      }

      console.log(clientsumarray)
      data_to_print.value = clientsumarray


    }

    function remove_zero(data) {

      //data.start   data.end  data.sum
      let i, my_value = [200, 300, 400, 500, 600, 700, 800, 900, 1000, 1100, 1200, 1300, 1400, 1500, 1600, 1700, 1800, 1900]
      let myarray = []
      console.log('hello')
      console.log(data)
      for (i = 0; i < 18; i++) {
        if (!(data.start[i] == 0 && data.end[i] == 0))
          myarray.push({ myvalue: my_value[i], start: data.start[i], end: data.end[i] })
      }
      return myarray
    }
    /*
    240101 240228 240331 
    1. 날짜를 추려서 버튼으로 한다.
    2. 날짜를 클릭하면
       평택단자
         1. 흡연실
            100)23 200)303 .....
            합).....
         2. 
    */


    return {
      open_cal_counter, my_clients,
      message_handler, data_to_print
    };
  },
};
</script>

<style scoped>
.grid_container_for_counter1 {
  display: grid;
  grid-template-columns: 15% 15% 15% 15% 15% 15%;
  grid-gap: 1px;
  background-color: lightblue;
  padding: 1px;
}

.grid_container_for_counter2 {
  display: grid;
  grid-template-columns: 10% 10% 10% 10% 10% 10% 10% 10% 10% 10%;
  grid-gap: 1px;
  background-color: lightblue;
  padding: 1px;
}
</style>