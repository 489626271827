<template>
  <!-- The w3-container class has a default 16px left and right padding, and no top or bottom padding -->

  <div v-if="loginOK">
    <MenuBar @message="message_handler">
  </MenuBar>
  <div v-if="(menu_selection == '재료')">
    <RefillManagement></RefillManagement>
  </div>
  <div v-if="menu_selection == '기계'">
    <MachineManagement></MachineManagement>
  </div>
  <div v-if="menu_selection == '카운타'">
    <CounterNote></CounterNote>
  </div>
<!--  <div v-if="menu_selection == '거래처'">
    <ClientManagement></ClientManagement>
  </div>
-->  
  <div v-if="menu_selection == '상품'">
    <ProductManagement></ProductManagement>
  </div>
  <div v-if="menu_selection == '문서'">
    <DocManagement></DocManagement>
  </div>
  </div>

</template>

<script>
import { ref, onMounted } from 'vue'
import { my_storage } from "@/classes/my_storage.js";

import MenuBar from '@/components/MenuBar.vue'
import ProductManagement from "@/views/product_management/ProductManagement.vue";
import RefillManagement from "@/views/refill_management/RefillManagement.vue";
//import ClientManagement from "@/views/client_management/ClientManagement.vue";
import MachineManagement from "@/views/machine_management/MachineManagement.vue";
//import CounterManagement from "@/views/counter_management/CounterManagement.vue";
import CounterNote from "@/views/counter_note/CounterNote.vue";
import DocManagement from "@/views/doc_management/DocManagement.vue";

export default {
  components: { MenuBar, ProductManagement, RefillManagement, /*ClientManagement,*/ MachineManagement, CounterNote, DocManagement },
  setup() {
    const menu_selection = ref("");
    function message_handler(message) {
      menu_selection.value = message.new_menu
    }

    const loginOK = ref(false)

    onMounted(() => {

      var inputString = prompt('password?');
      if (inputString!=='937756') {
        confirm('incorrect password')
        loginOK.value = false
      }
      else loginOK.value = true


      let body = { action: "get", params: { any: "any" } }
      my_storage.fetch_can_products(body)
      my_storage.fetch_coeffee_products(body)
      my_storage.fetch_clients(body)
      my_storage.fetch_machines(body)
      my_storage.fetch_can_level(body)
      my_storage.fetch_box_level(body)
      my_storage.fetch_products_reception(body)
      my_storage.fetch_docs(body)
      my_storage.fetch_counters(body)
    });


    return {
      menu_selection, message_handler, loginOK
    };
  },
};
</script>

<style>
/*ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #333;
}

li {
  float: left;
}

li p {
  display: block;
  color: white;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

li p:hover {
  background-color: #111;
}*/
</style>
