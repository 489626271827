<template>
  <div class="backdrop">
    <div class="modal">
      <div class="w3-animate-top">
        <div class="w3-container w3-teal">
          <header class="w3-display-container">
            <span @click="$emit('close')" class="w3-button w3-display-topright">&times;</span>
            <div v-if="mode=='can'">
              <h2 v-if="act=='add'">캔등록</h2>
              <h2 v-else>캔수정</h2>
            </div>
            <div v-else>
              <h2 v-if="act=='add'">커피등록</h2>
              <h2 v-else>커피수정</h2>
            </div>
          </header>
        </div>

        <div class="w3-container">
          <form @submit.prevent="handleSubmit">
            <label class="w3-text-teal"><b>상품이름</b></label>
            <input class="w3-input w3-border w3-light-grey" type="text" required v-model="name">
          </form>
        </div>

        <footer class="w3-container w3-teal w3-display-container"
          style="margin-top:10px;padding-top:5px; padding-bottom:5px">
          <button @click="handleSubmit">상품등록</button>
          <button @click="$emit('close')" class="w3-button">취소</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, computed } from "vue";

export default {
  props: ["act", "mode", "product", "products"],
  setup(props, ctx) {

    console.log('setup in product modeal.vue')
    console.log('act=' + props.act)
    console.log('mode=' + props.mode)
    console.log(props.products)
    //const { products, load, error  } = product_db_access()

    const name = ref("");
    if (props.act == 'update') {
      name.value = props.product.name
    }

    const productInput = ref(null);
    //console.log('productmodal.vue')
    //console.log(props.products)

    function handleSubmit() {
      let result = props.products.find((item) => item.name == name.value);
      let new_product, updated_product;
      //console.log("result " + result);
      if (props.act == "add") {
        if (result == undefined) {
          new_product = {
            action: "add",
            params: {
              id: "" + Date.now(),
              name: name.value
            },
          };
          ctx.emit("message", new_product);
          ctx.emit("close");
        } else {
          prompt("이미 있는 상품입니다. 확인을 누르고 다시 하세요");
          return;
        }
      } else {
        updated_product = {
          action: "update",
          params: {
            id: props.product.id,
            name: name.value
          },
        };
        ctx.emit("message", updated_product);
        ctx.emit("close");
      }
    }

    return { name, handleSubmit, productInput };
  },
};
</script>

<style>
</style>