
<template>
  <DisplayRecords
    what="reception_level"
    :records="products_reception_records"
    :current="current"
    @message="reception_level_message_handler"
  >
  </DisplayRecords>
  <br />
    <!-- all을 하나 선택하면 all과 box_level_records을 근거로 가져간 상품기록을 표시한다. -->
    <!-- 상기 'all'로 입력된 전체 투입 재료 기록과 
         거래처별로 입력된 투입재료 기록으로부터
         평택단자 투입재료 기록을 자동 확정하여 저장한다.
    -->
    <PengTackReception>
    </PengTackReception>
    <br>

    <PrintProductsReception
    ></PrintProductsReception>

</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import DisplayRecords from "@/views/refill_management/display_records/DisplayRecords.vue";
import PengTackReception from "@/views/refill_management/reception_level/PengTackReception.vue";
import PrintProductsReception from "@/views/refill_management/print_records/PrintProductsReception.vue";
import { ref, computed } from "vue";
export default {
  // can level records    깡통단위의 거래처별 상품 투입내역
  // current   ---  현재 선택된 거래처이름 또는 all
  props: ["current"],
  components: {
    DisplayRecords,
    PengTackReception,
    PrintProductsReception,
  },

  setup(props, ctx) {
    // put_products_records -- 깡통단위 거래처별 투입상품정보
    // finalRecords --- 박스단위 거래처별 투입상품 정보
    // filteredRecords,    //sort된 깡통단위 거래처별 투입정보
    // receive_products    // 토탈 박스단위 가져온 상품정보

    const products_reception_records = computed(() =>
      my_storage.get("products_reception_records")
    );


    function reception_level_message_handler(message) {
      //console.log("reception level message in refillmanagement.vue");
      //console.log(message);
      if (message.link_db == "yes")
        my_storage.fetch_products_reception(message);
      switch (message.action) {
        // add from modal
        case "add":
          my_storage.add("products_reception_records", message);
          break;

        // update, delete, mark ....  from SingleRecord
        case "update":
          my_storage.update("products_reception_records", message);
          break;

        case "delete":
          my_storage.delete("products_reception_records", message);
          break;

        // update, delete, mark ....  from SingleRecord
        // mark for including it for 박스단위 환산
        case "mark":
          my_storage.mark("products_reception_records", message);
          break;

        case "reset":
          my_storage.reset("products_reception_records", message);
          break;
      }
    }

    return {
      products_reception_records,
      reception_level_message_handler,
    };
  },
};
</script>

<style scoped>
.mytitleclass {
  border-style: groove;
  /*background-color: black;
  color: white;*/
}
</style>