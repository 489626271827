const array_utils = () => {

  const products_line = (slot_info) => {
    let n_slots = slot_info.n_slots
    let temp = slot_info.slots.split("/")
    let remainder = n_slots % 3
    let n_lines = Math.floor(n_slots / 3)
    let myarray = [], temparray = []
    let i, j
    for (i = 0; i < n_lines; i++) {
      temparray = []
      for (j = 0; j < 3; j++) {
        temparray.push((3 * i + j + 1).toString() + ". " + temp[3 * i + j])
      }
      myarray.push(temparray)
    }
    temparray = []
    if (remainder != 0) {
      for (j = 0; j < remainder; j++) {
        temparray.push((3 * n_lines + j + 1).toString() + ". " + temp[3 * n_lines + j])
      }
      for (j = remainder; j < 3; j++) {
        temparray.push((3 * n_lines + j + 1).toString() + ". " + temp[3 * n_lines + j])
      }
      myarray.push(temparray)
    }
    //console.log('myarray')
    //console.log(myarray)
    return myarray
  }

  const counter_line = (slot_info) => {
    let myvalue = ["2)", "3)", "4)", "5)", "6)", "7)", "8)", "9)", "10)", "11)", "12)", "13)", "14)", "15)", "16)", "17)", "18)", "19)"]
    let n_slots = slot_info.n_slots
    let temp = slot_info.slots.split("/")
    let remainder = n_slots % 3
    let n_lines = Math.floor(n_slots / 3)
    let myarray = [], temparray = []
    let i, j
    for (i = 0; i < n_lines; i++) {
      temparray = []
      for (j = 0; j < 3; j++) {
        temparray.push(myvalue[3 * i + j] + " " + temp[3 * i + j])
      }
      myarray.push(temparray)
    }
    temparray = []
    if (remainder != 0) {
      for (j = 0; j < remainder; j++) {
        temparray.push(myvalue[3 * n_lines + j] + " " + temp[3 * n_lines + j])
      }
      for (j = remainder; j < 3; j++) {
        temparray.push(myvalue[3 * n_lines + j] + " " + temp[3 * n_lines + j])
      }
      myarray.push(temparray)
    }
    //console.log('myarray')
    //console.log(myarray)
    return myarray
  }

  const initialize_my_array = (size) => {

    let temp = [], i = 0
    for (i = 0; i < size; i++) {
      temp.push({ name: '', quan: 0 })
    }
    return temp
  }

  const initialize_my_array_price = (size) => {

    let temp = [], i = 0
    for (i = 0; i < size; i++) {
      temp.push({ client: '', price: 0 })
    }
    return temp
  }

  // remove the array element with zero quan
  const remove_zero_quan = (myarray) => {
    return myarray.filter((item) => item.quan != 0)
  }

  // myarray1의 시작부분을 myarray2로 대치한다.
  const replace_temp1 = (myarray1, myarray2) => {
    let i, temp = myarray1
    for (i = 0; i < myarray2.length; i++) {
      temp[i] = myarray2[i]
    }
    return temp;
  }

  const format_single = (single) => {
    let can, coeffee
    can = JSON.parse(single.can_products)
    can = remove_zero_quan(can)
    coeffee = JSON.parse(single.coeffee_products)
    coeffee = remove_zero_quan(coeffee)
    return { can: can, coeffee: coeffee }
  }

  // reception record를 처리하기 쉬운 형태로 일단 변환
  const format_for_processing = (myarray) => {
    let i, o_array = [], temp
    for (i = 0; i < myarray.length; i++) {
      temp = format_single(myarray[i])
      o_array.push(
        {
          date_used: myarray[i].date_used,
          client: myarray[i].client,
          can: temp.can,
          coeffee: temp.coeffee
        }
      )
    }
    return o_array;
  }


  // 아모텍 + 대동
  const group_amo_daedong = (myarray) => {
    let tempx, temparray = []

    // 전체
    tempx = myarray.filter((item) => item.client == 'all')
    temparray.push({ client: "전체", date_used: tempx[0].date_used, can: tempx[0].can_products, coeffee: tempx[0].coeffee_products })

    // 평택단자
    tempx = myarray.filter((item) => item.client == '평택단자')
    temparray.push({ client: "평택단자", date_used: tempx[0].date_used, can: tempx[0].can_products, coeffee: tempx[0].coeffee_products })

    // 아모텍+대동
    tempx = myarray.filter((item) => item.client == '포승대동' || item.client == '포승아모텍')
    let temp1 = sum_products(tempx)
    let can = sum_of_same_key_values(temp1.can);
    let coeffee = sum_of_same_key_values(temp1.coeffee);
    temparray.push({ client: "대동+아모텍", date_used: tempx[0].date_used, can: JSON.stringify(can), coeffee: JSON.stringify(coeffee) })

    // 화성단자
    tempx = myarray.filter((item) => item.client == '화성단자')
    temparray.push({ client: "화성단자", date_used: tempx[0].date_used, can: tempx[0].can_products, coeffee: tempx[0].coeffee_products })

    // 화성덴소
    tempx = myarray.filter((item) => item.client == '화성덴소')
    temparray.push({ client: "화성덴소", date_used: tempx[0].date_used, can: tempx[0].can_products, coeffee: tempx[0].coeffee_products })

//    console.log('in prepre')
    //console.log(temparray)
    return temparray

  }

  const find_negative_quaned_products = (myarray, kind) => {
    let i, j, temp, negative_products=[]
    for (i = 0; i < myarray.length; i++) {
      if (kind=='can')
      temp = JSON.parse(myarray[i].can);
      else 
      temp = JSON.parse(myarray[i].coeffee);
      for (j = 0; j < temp.length; j++) {
        if (temp[j].quan < 0) negative_products.push(temp[j].name);
      }
    }
    return negative_products
  }

  const sum_of_same_key_values = (myarray) => {

    var holder = {};

    myarray.forEach(function (d) {
      if (holder.hasOwnProperty(d.name)) {
        holder[d.name] = holder[d.name] + d.quan;
      } else {
        holder[d.name] = d.quan;
      }
    });

    var obj2 = [];

    for (var prop in holder) {
      obj2.push({ name: prop, quan: holder[prop] });
    }

    //console.log(obj2);
    return obj2.filter(item => item.name !== '')
  }

  const sum_of_products = (myarray, kind) => {
    let i, o_return = [], product, j
    for (i = 0; i < myarray.length; i++) {
      if (kind == 'can') product = myarray[i].can
      else product = myarray[i].coeffee
      for (j = 0; j < product.length; j++) {
        if (product[j].quan != 0) o_return.push(product[j])
      }
    }
    return o_return;
  }



  const sum_products = (myarray) => {

    let i, temp, can = [], coeffee = []

    for (i = 0; i < myarray.length; i++) {
      temp = JSON.parse(myarray[i].can_products);
      can = can.concat(temp);
      temp = JSON.parse(myarray[i].coeffee_products);
      coeffee = coeffee.concat(temp);
    }
    //console.log(obj2);
    return { can: can, coeffee: coeffee }
  }

  const sum_of_can_coeffee_products = (myarray) => {
    let tempx = sum_products(myarray);
    let temp = { can: [], coeffee: [] }

    // 평택단자를 제외한 모든 거래처의 상품별 박스단위 합산물량
    temp.can = sum_of_same_key_values(tempx.can);
    temp.coeffee = sum_of_same_key_values(tempx.coeffee);
    return temp;
  }

  const negate_quantity = (products) => {
    let i
    let can = products.can
    let coeffee = products.coeffee

    for (i = 0; i < can.length; i++) {
      can[i].quan = -can[i].quan;
    }
    for (i = 0; i < coeffee.length; i++) {
      coeffee[i].quan = -coeffee[i].quan;
    }
    //console.log(obj2);
    return { can: can, coeffee: coeffee }
  }

  const negate_quantity2 = (product) => {
    let i, myproduct = product

    for (i = 0; i < myproduct.length; i++) {
      myproduct[i].quan = -myproduct[i].quan;
    }
    return myproduct
  }



  return {
    sum_of_same_key_values, negate_quantity, initialize_my_array,
    remove_zero_quan, sum_products, replace_temp1, sum_of_can_coeffee_products,
    initialize_my_array_price, format_for_processing, sum_of_products,
    negate_quantity2, group_amo_daedong, find_negative_quaned_products,
    products_line, counter_line
  }
}

export default array_utils
