<template>
  <div class="my-w3-container">
    <div class="w3-display-container w3-green">
      <FilterNav mytitle="제품투입" @filterChange="current = $event" :clients="clients" :current="current" />
    </div>
  </div>

  <!-- 거래처이름으로 서치 -->
  <!--  <FilterNav @filterChange="current = $event" :clients="clients" :current="current" />-->

  <div v-if="error">{{ error }}</div>

  <!--
      CanLevel
        1. display can_level_records
        2. add / edit / delete single record
        3. mark a record (to inclucde for the processing in box-level)
        4. reset markings
    -->
  <div class="w3-border">
    <CanLevel :current="current">
    </CanLevel>
  </div>

  <!--
      BoxLevel
        1. display box_level_records
        2. edit a single record (only update....)
        3. clear all records
    -->
  <BoxLevel :current="current">
  </BoxLevel>

  <!-- 박스 단위 가져간 상품 기록 -->
  <!-- 이곳에서 박스단위 가져간 재료 기록을 등록한다. -->
  <!-- 또한, 기록중에서 정산할 기록들을 선택한다.. -->
  <!-- 여기서 선택된 기록들이 아래 CalcultePT 에서 평택단자 재료투입기록이 자동 저장된다.-->
  <ReceptionLevel :current="current">
  </ReceptionLevel>

</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import FilterNav from "@/components/FilterNav.vue";
import CanLevel from "@/views/refill_management/can_level/CanLevel.vue";
import BoxLevel from "@/views/refill_management/box_level/BoxLevel.vue";
import ReceptionLevel from "@/views/refill_management/reception_level/ReceptionLevel.vue";
import { ref, computed } from "vue";

export default {
  components: {
    FilterNav,
    CanLevel,
    BoxLevel,
    ReceptionLevel,
  },
  setup() {
    const current = ref("all");
    const clients = computed(() => my_storage.get("clients"));

    return {
      clients,
      current,

    };
  },
};
</script>

<style scoped>
.grid_container {
  display: grid;
  grid-template-columns: 12% auto 12% auto 12% auto;
  grid-gap: 1px;
  background-color: lightblue;
  padding: 1px;
}

.subtitle {
  grid-column: 1 / span 6;
  grid-row: 1;
}
</style>