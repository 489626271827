<template>
  <div class="backdrop" @click.self="$emit('close')">
    <div class="modal">
      <p>2구간 카운타 계산</p>
      <form @submit.prevent="handleSubmit">
        <label>title:</label>
        <input type="text" required v-model="day1" />
        <label>title:</label>
        <input type="text" required v-model="day2" />

        <button class="btn danger">2구간 카운타 합산</button>
      </form>
    </div>
  </div>
</template>

<script>
//import {props} from 'vue'
import { ref } from "vue";
export default {
  props: [],
  setup(props, ctx) {
    const client_name = ref("");
    const title = ref("");
    const contents = ref("");
    const day1 = ref("")
    const day2 = ref("")

    function handleSubmit() {
      let message = {
        action: "compute",
        params: {
          day1: day1.value,
          day2: day2.value
        },
      };

      ctx.emit("message", message);
      ctx.emit("close");
    }

    return {
      day1, day2,

      handleSubmit,
    };
  },
};
</script>

<style scoped>
/*
.modal {
  width: 400px;
  padding: 10px;
  margin: 50px auto;
  background: white;
  border-radius: 10px;
}
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}
label {
  color: #aaa;
  display: inline-block;
  margin: 5px 0 5px;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input,
select {
  display: block;
  padding: 5px 3px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
input[type="checkbox"] {
  display: inline-block;
  width: 16px;
  margin: 0 3px 0 0;
  position: relative;
  top: 2px;
}*/
</style>