<template>
  <div class="backdrop">
    <div class="modal">
      <div class="w3-animate-top">
        <div class="w3-container w3-teal">
          <header class="w3-display-container">
            <span @click="$emit('close')" class="w3-button w3-display-topright">&times;</span>
            <h2 v-if="mode == 'add'">새 문서 등록</h2>
            <h2 v-else>문서수정</h2>
          </header>
        </div>

        <div class="w3-container">
          <form @submit.prevent="handleSubmit">
            <label class="w3-text-teal">거래처</label>
            <select class="w3-select" v-model="client_name" required>
              <option v-for="client in clients" :key="client.name" :value="client.name">
                {{ client.name }}
              </option>
            </select>
            <label class="w3-text-teal">문서제목</label>
            <input class="w3-input" type="text" required v-model="title" />
            <label class="w3-text-teal">문서내용</label>
            <textarea class="w3-input" v-model="contents" autocomplete="off" autocorrect="off" autocapitalize="off"
              data-gramm="false" spellcheck="false" rows="10" cols="40">
          </textarea>
          </form>
        </div>
        <footer class="w3-container w3-teal w3-display-container"
          style="margin-top:5px;padding-top:5px; padding-bottom:5px">
          <button @click="handleSubmit">저장</button>
          <button @click="$emit('close')" class="w3-button">취소</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
//import {props} from 'vue'
import { ref } from "vue";
export default {
  props: ["doc", "clients", "mode"],
  setup(props, ctx) {
    const client_name = ref("");
    const title = ref("");
    const contents = ref("");

    // edit mode인 경우 machine을 props로 전달받는다.
    if (props.mode == "update") {
      client_name.value = props.doc.client;
      title.value = props.doc.title;
      contents.value = props.doc.contents;
    }

    //console.log("clients in basicinfomodal.vue");
    //console.log(props.clients);

    function handleSubmit() {
      let id;
      if (props.mode == "update") {
        id = props.doc.id;
      } else id = Date.now();

      let doc_info = {
        action: props.mode,
        params: {
          id: id,
          client: client_name.value,
          title: title.value,
          contents: contents.value,
        },
      };
      //console.log("doc_info docmodal.vue");
      //console.log(doc_info);

      ctx.emit(props.mode, doc_info);
      //ctx.emit("add", doc_info);
      ctx.emit("close");
    }

    return {
      title,
      contents,
      client_name,

      handleSubmit,
    };
  },
};
</script>

<style scoped>

</style>