<template>
  <div class="my-w3-container">
    <div v-if="what == 'can_level'">
      <div class="w3-container w3-blue" style="margin-top:3px">
        <div class="w3-row">
          <div class="w3-col s6">
            <h4 class="w3-button" @click="show_detail()">깡통단위기록</h4>
          </div>
          <p class="w3-button w3-col s3" @click="showAddModal = true">추가</p>
          <p class="w3-button w3-col s3" @click="reset_mark">초기화</p>
        </div>
      </div>
    </div>
    <div v-if="what == 'box_level'">
      <div class="w3-container w3-blue" style="margin-top:3px">
        <div class="w3-row">
          <div class="w3-col s6">
            <h4 class="w3-button" @click="show_detail()">박스단위기록</h4>
          </div>
        </div>
      </div>
    </div>
    <div v-if="what == 'reception_level'">
      <div class="w3-container w3-blue" style="margin-top:3px">
        <div class="w3-row">
          <div class="w3-col s6">
            <h4 class="w3-button" @click="show_detail()">재료투입기록</h4>
          </div>
          <p class="w3-button w3-col s3" @click="showAddModal = true">추가</p>
        </div>
      </div>
    </div>
  </div>

  <!-- records = filtered_can_level_records or 
                 filtered_box_level_records
  -->
  <div v-if="show_detail_flag">
    <div v-if="records.length">
      <div v-for="record in records" :key="record">
        <!--
        SingleRecord에서 delete update mark 처리한다.
        delete는 재료투입삭제
        mark는 박스단위 환산에 포함할 record를 선정
        update는 재료투입수정 (SingleRecord에서 modal을 통해 처리됨)
      -->
        <SingleRecord :what="what" :record="record" :current="current"
          :marked_can_level_records="marked_can_level_records" @message="message_handler">
        </SingleRecord>
      </div>
    </div>
  </div>

  <!-- 거래처별 상품투입을 깡통 단위로 입력하는 modal -->
  <div v-if="showAddModal && (what == 'can_level' || what == 'reception_level')">
    <RefillModal mode="add" :current="current" :what="what" @message="message_handler" @close="showAddModal = false">
    </RefillModal>
  </div>
</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import SingleRecord from "@/views/refill_management/display_records/SingleRecord.vue";
import RefillModal from "@/views/refill_management/modals/RefillModal.vue";
import { ref, computed } from "vue";
export default {
  // can level records    깡통단위의 거래처별 상품 투입내역
  // current   ---  현재 선택된 거래처이름 또는 all
  props: ["what", "records", "current", "marked_can_level_records"],
  components: {
    SingleRecord,
    RefillModal /* SingleCanLevelRecord, AddRecord*/,
  },

  setup(props, ctx) {

    const show_detail_flag = ref(false)

    function show_detail() {
      show_detail_flag.value = !show_detail_flag.value
    }
    // put_products_records -- 깡통단위 거래처별 투입상품정보
    // finalRecords --- 박스단위 거래처별 투입상품 정보
    // filteredRecords,    //sort된 깡통단위 거래처별 투입정보
    // receive_products    // 토탈 박스단위 가져온 상품정보

    //console.log('in displayrecords.vue what==')
    //console.log(props.what)
    /*if (props.what == "box_level") {
      console.log("records in setup in display records.vue when box_level");
      console.log(props.records);
    }*/
    const marked_can_level_records = computed(() => my_storage.get());

    // handle messages from SingleRecord... and modal
    function message_handler(message) {
      ctx.emit("message", message);
    }

    // 깡통단위 투입 목록에서 선택한것 모두 리셋함.
    // put_products_details check_field='0'
    function reset_mark() {
      let message = {
        link_db: "no",
        action: "reset",
        params: { any: "any" },
      };
      ctx.emit("message", message);
    }

    // modal 관련 변수 및 함수
    const showAddModal = ref(false);

    return {
      show_detail, show_detail_flag,
      // 거래처이름으로 filter 된 결과

      // SingleRecord와 modal의 emit 처리위한 함수들
      message_handler,

      // 깡통단위 투입을 박스단위 투입하기 위해 선택된
      // 항목을 초기화하는 함수
      reset_mark,

      // modal 관련
      showAddModal,
    };
  },
};
</script>

<style scoped>
.mytitleclass {
  border-style: groove;
  /*background-color: black;
  color: white;*/
}
</style>