<template>
  <div class="backdrop">
    <div class="modal">
      <div class="w3-animate-top">
        <div class="w3-container w3-teal">
          <header class="w3-display-container">
            <span @click="$emit('close')" class="w3-button w3-display-topright">&times;</span>
            <h2>슬롯수정</h2>
          </header>
        </div>

        <div class="w3-container">
          <form @submit.prevent="handleSubmit">
            <div v-for="n in machine.n_can_slots / 2" :key="n">
              <div class="w3-row">
                <div v-for="i in 2" :key="i">
                  <label style="margin-top:7px" class="w3-col s1">{{ 2 * (n - 1) + i }}</label>
                  <!--<input type="text" required v-model="slots[n-1]">-->
                  <select class="w3-col s4 w3-select" v-model="can_slots[2 * (n - 1) + i - 1]">
                    <option v-for="can in can_products" :key="can.name" :value="can.name">{{ can.name }}</option>
                  </select>
                  <div class="w3-col s1"><span>&nbsp;</span></div>
                </div>
              </div>
            </div>
            <div v-for="n in machine.n_coeffee_slots / 2" :key="n">
              <div class="w3-row">
                <div v-for="i in 2" :key="i">
                  <label style="margin-top:7px" class="w3-col s1">{{ 2 * (n - 1) + i }}</label>
                  <!--<input type="text" required v-model="slots[n-1]">-->
                  <select class="w3-col s4 w3-select" v-model="coeffee_slots[2 * (n - 1) + i - 1]">
                    <option v-for="coeffee in coeffee_products" :key="coeffee.name" :value="coeffee.name">{{ coeffee.name }}</option>
                  </select>
                  <div class="w3-col s1"><span>&nbsp;</span></div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <footer class="my-w3-container w3-teal w3-display-container"
          style="margin-top:5px;padding-top:5px; padding-bottom:5px">
          <button @click="handleSubmit()" class="w3-button">저장</button>
          <button @click="$emit('close')" class="w3-button">취소</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
//import {props} from 'vue'
import { ref, computed } from 'vue'
import { my_storage } from "@/classes/my_storage.js";

export default {
  props: ['machine',],
  setup(props, ctx) {
    //const slots = ref([])
    //const { can_products, products_db_api, error  } = product_db_access()

    const can_slots = computed(() => props.machine.can_slots.split("/"));
    const coeffee_slots = computed(() => props.machine.coeffee_slots.split("/"));

    const can_products = computed(() => my_storage.get("can_products"));
    const coeffee_products = computed(() => my_storage.get("coeffee_products"));

    props.machine.n_can_slots
    props.machine.n_coeffee_slots


    function handleSubmit() {
      let message = {
        action: "update",
        params: {
          client: props.machine.client,
          name: props.machine.name,
          location: props.machine.location,
          machine_number: props.machine.machine_number,
          card_terminal_number: props.machine.card_terminal_number,
          kind: props.machine.kind,
          n_can_slots: props.machine.n_can_slots,
          n_coeffee_slots: props.machine.n_coeffee_slots,
          id: props.machine.id,
          can_slots: can_slots.value.join('/'),
          coeffee_slots: coeffee_slots.value.join('/'),
          counter: props.machine.counter,
          counter_date: props.machine.counter_date,
        },
      };

      ctx.emit("message", message);
      ctx.emit('close')
    }

    return {
      can_products, coeffee_products,

      can_slots, coeffee_slots,

      handleSubmit,
    }
  }
}
</script>

<style scoped>
/*
    .modal {
        width: 400px;
        padding: 20px;
        margin: 100px auto;
        background: white;
        border-radius: 10px;
    }
    .backdrop {
        top: 0;
        position: fixed;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
    }
    form {
      max-width: 420px;
      margin: 5px auto;
      background: white;
      text-align: left;
      padding: 5px;
      border-radius: 10px;
    }
    label {
      color: #aaa;
      display: inline-block;
      margin: 5px 0 5px;
      font-size: 0.6em;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
    }
    input, select {
      display: block;
      padding: 2px 2px;
      width: 100%;
      box-sizing: border-box;
      border: none;
      border-bottom: 1px solid #ddd;
      color: #555;    
    }
    input[type="checkbox"] {
      display: inline-block;
      width: 16px;
      margin: 0 10px 0 0;
      position: relative;
      top: 2px;
    }
    .same_line {
      display: inline;
    }
*/
</style>