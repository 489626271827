<template>
  <div class="my-w3-container">
    <div class="w3-display-container w3-green">
      <FilterNav mytitle="문서관리" @filterChange="current = $event" :clients="clients" :current="current" />
    </div>
  </div>
  <div class="my-w3-container">
    <button class="w3-button" @click="showAddDocModal = true">새로운문서등록</button>
  </div>

  <div v-if="error">{{ error }}</div>
  <div v-if="filteredDocs.length">
    <div v-for="doc in filteredDocs" :key="doc">
      <DisplayDoc :doc="doc" :docs="docs" :clients="clients" @update="emit_handler" />
    </div>
  </div>
  <div v-else>Loading</div>

  <div v-if="showAddDocModal">
    <!--doc은 add의 용도에서는 더미로 패스함.-->
    <DocModal mode="add" :doc="clients" :clients="clients" @add="emit_handler" @close="showAddDocModal = false">
    </DocModal>
  </div>
</template>

<script>
//import { onMounted } from 'vue'
// @ is an alias to /src
import DisplayDoc from "@/views/doc_management/display_doc/DisplayDoc.vue";
import DocModal from "@/views/doc_management/modals/DocModal.vue";
import FilterNav from "@/components/FilterNav.vue";
import { my_storage } from "@/classes/my_storage.js";
import { computed } from "vue";
import { ref } from "vue";
export default {
  name: "MachineManagement",
  components: { DisplayDoc, FilterNav, DocModal },
  setup() {
    const current = ref("all");
    const docs = computed(() => my_storage.get("docs"));
    const clients = computed(() => my_storage.get("clients"));

    // flag for open modal for adding new machine
    const showAddDocModal = ref(false);

    const filteredDocs = computed(() => {
      if (current.value == "all") {
        return docs.value;
      }
      return docs.value.filter(
        (doc) => doc.client == current.value
      );
    });

    function emit_handler(body) {
      //console.log("body in machine management.vue");
      //console.log(body);
      my_storage.fetch_docs(body);
      switch (body.action) {

        case "update":
          my_storage.update("docs", body)
          break;

        case "delete":
          my_storage.delete("docs", body)
          break;

        case "add":
          my_storage.add("docs", body)
          break;
      }
    }

    return {
      current, // 현재 선택된 거래처 이름

      docs, // 기계 목록
      filteredDocs, // 거래처이름으로 선택된 기계 목록

      clients, // 거래처이름 목록

      showAddDocModal,

      emit_handler,
    };
  },
};
</script>

<style>
/*.filter-nav button {
  background: none;
  border: none;
  color: #bbb;
  outline: none;
  font-size: 12px;
  margin-right: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
}*/
</style>