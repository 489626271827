<template>
  <DisplayRecords 
    what="can_level" 
    :records="filteredCanLevelRecords" 
    :current="current"
    @message="can_level_message_handler">
  </DisplayRecords>

  <br />
</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import { ref, computed } from "vue";
import DisplayRecords from "@/views/refill_management/display_records/DisplayRecords.vue";

export default {
  // can level records    깡통단위의 거래처별 상품 투입내역
  // current   ---  현재 선택된 거래처이름 또는 all
  props: ["current",],
  components: {
    DisplayRecords,
  },

  setup(props, ctx) {
    // put_products_records -- 깡통단위 거래처별 투입상품정보
    // finalRecords --- 박스단위 거래처별 투입상품 정보
    // filteredRecords,    //sort된 깡통단위 거래처별 투입정보
    // receive_products    // 토탈 박스단위 가져온 상품정보

    const can_level_records = computed(() =>
      my_storage.get("can_level_records")
    );

    const filteredCanLevelRecords = computed(() => {
      //console.log('in canlevel filtered can level')
      //console.log(props.current)
      //console.log(can_level_records.value)
      if (props.current == "all") {
        return can_level_records.value;
      }
      return can_level_records.value.filter(
        (record) => record.client == props.current
      );
    });

    function can_level_message_handler(message) {
      //console.log("can level message in refillmanagement.vue");
      //console.log(message);
      if (message.link_db == "yes") my_storage.fetch_can_level(message);
      switch (message.action) {
        // add from modal
        case "add":
          my_storage.add("can_level_records", message);
          break;

        // update, delete, mark ....  from SingleRecord
        case "update":
          my_storage.update("can_level_records", message);
          break;

        case "delete":
          my_storage.delete("can_level_records", message);
          break;

        // update, delete, mark ....  from SingleRecord
        // mark for including it for 박스단위 환산
        case "mark":
          my_storage.mark("can_level_records", message);
          break;

        case "reset":
          my_storage.reset("can_level_records", message);
          break;
      }
    }

    return {
      can_level_message_handler,

      filteredCanLevelRecords,
      // 거래처이름으로 filter 된 결과

    };
  },
};
</script>

<style scoped>
.mytitleclass {
  border-style: groove;
  /*background-color: black;
  color: white;*/
}
</style>