<template>
  <div class="backdrop">
    <div class="modal">
      <div class="w3-animate-top">
        <div class="w3-container w3-teal">
          <header class="w3-display-container">
            <span @click="$emit('close')" class="w3-button w3-display-topright">&times;</span>
            <h2>카운타수정 - {{ machine.name }}</h2>
            <p style="margin-left:20px">이전카운타날자: {{ counter_date }}</p>
          </header>
        </div>

        <div class="w3-container">
          <form @submit.prevent="handleSubmit">
            <div v-for="n in 9" :key="n">
              <div class="my-w3-container">
                <div class="w3-row">
                  <div v-for="i in 2" :key="i">
                    <label style="margin-top:8px" class="w3-col s1 w3-text-teal w3-right-align">{{ counter_value[2 * (n
              - 1) + i - 1]
                      }}</label>
                    <input class="w3-input w3-col s4" type="number" required v-model="counter[2 * (n - 1) + i - 1]" />
                    <div class="w3-col s1"><span>&nbsp;</span></div>
                  </div>
                </div>
              </div>
            </div>

            <div class="my-w3-container">
              <div class="w3-row">
                <label style="margin-top:8px" class="w3-col s1 w3-text-teal w3-right-align">total</label>
                <input class="w3-input w3-col s2" type="number" v-model="counter[19]" />
              </div>
            </div>
          </form>
        </div>
        <footer class="my-w3-container w3-teal w3-display-container"
          style="margin-top:5px;padding-top:5px; padding-bottom:5px">
          <button @click="handleSubmit()" class="w3-button">저장</button>
          <button @click="$emit('close')" class="w3-button">취소</button>
        </footer>

      </div>
    </div>
  </div>
</template>

<script>
//import {props} from 'vue'
import { ref, computed } from "vue";
import time_utils from "@/composables/utils/time_utils.js";
import counter_utils from "@/composables/utils/counter_utils.js";

export default {
  props: ["machine"],
  setup(props, ctx) {
    //const slots = ref([])
    //const { can_products, products_db_api, error  } = product_db_access()

    const { counter_value } = counter_utils();

    // counters = [{v:200,q:0},....]
    const counter = computed(() => JSON.parse(props.machine.counter));
    const counter_date = computed(() => props.machine.counter_date);
    const { current_date } = time_utils();
    function handleSubmit() {
      let message = {
        action: "update_counter",
        params: {
          // basic info의 add or update 처리
          client: props.machine.client,
          name: props.machine.name,
          location: props.machine.location,
          machine_number: props.machine.machine_number,
          card_terminal_number: props.machine.card_terminal_number,
          kind: props.machine.kind,
          n_can_slots: props.machine.n_can_slots,
          n_coeffee_slots: props.machine.n_coeffee_slots,
          // basic_info add / update의 경우 
          // can_slots, coeffee_slots 정보는 
          //   add의 경우는 ""로,
          //   update의 경우는 props의 값을 대치한다.
          id: props.machine.id,
          id2: "" + Date.now(),
          can_slots: props.machine.can_slots,
          coeffee_slots: props.machine.coeffee_slots,
          counter: JSON.stringify(counter.value),
          counter_date: current_date()
        }
      }

      ctx.emit("message", message);
      ctx.emit("close");
    }

    return {
      counter, counter_date, counter_value,
      handleSubmit,
    };
  },
};
</script>

<style scoped>
/*
.modal {
  width: 400px;
  padding: 10px;
  margin: 10px auto;
  background: white;
  border-radius: 10px;
}
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
form {
  width: 500px;
  margin: 5px auto;
  background: white;
  text-align: left;
  padding: 5px;
  border-radius: 10px;
}
label {
  color: #aaa;
  display: inline-block;
  margin: 5px 0 5px;
  font-size: 1.1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input,
select {
  display: block;
  font-size: 14px;
  padding: 2px 2px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
input[type="checkbox"] {
  display: inline-block;
  font-size: 14px;
  width: 16px;
  margin: 0 10px 0 0;
  position: relative;
  top: 2px;
}

input[type="number"] {
  display: inline-block;
  font-size: 14px;
  width: 300px;
  margin: 0 10px 0 30px;
  position: relative;
  top: 2px;
}

.option {
  font-size: 14px;
  padding: 5px;
}

.same_line {
  display: inline;
}*/
</style>