
<template>
  <DisplayRecords
    what="box_level"
    :records="box_level_records"
    :current="current"
    :marked_can_level_records="marked_can_level_records"
    @message="box_level_message_handler"
  >
  </DisplayRecords>
  <br />
</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import DisplayRecords from "@/views/refill_management/display_records/DisplayRecords.vue";
import { ref, computed } from "vue";
export default {
  // can level records    깡통단위의 거래처별 상품 투입내역
  // current   ---  현재 선택된 거래처이름 또는 all
  props: ["current", ],
  components: {
    DisplayRecords,
  },

  setup(props, ctx) {
    // put_products_records -- 깡통단위 거래처별 투입상품정보
    // finalRecords --- 박스단위 거래처별 투입상품 정보
    // filteredRecords,    //sort된 깡통단위 거래처별 투입정보
    // receive_products    // 토탈 박스단위 가져온 상품정보

    const marked_can_level_records = computed(() =>
      my_storage.get("marked_can_level_records")
    );

    const box_level_records = computed(() => {
      let temp = my_storage.get("box_level_records");
      //console.log("box level records in boxlevel.vue in setup");
      //console.log(box_level_records);
      return temp;
    });


    function box_level_message_handler(message) {
      //console.log("box level message in refillmanagement.vue");
      //console.log(message);
      if (message.link_db == "yes") my_storage.fetch_box_level(message);
      switch (message.action) {
        // add from modal
        case "add":
          my_storage.add("box_level_records", message);
          break;

        // update, delete, mark ....  from SingleRecord
        case "update":
          my_storage.update("box_level_records", message);
          break;

        case "delete":
          my_storage.delete("box_level_records", message);
          break;

        // update, delete, mark ....  from SingleRecord
        // mark for including it for 박스단위 환산
        case "mark":
          my_storage.mark("box_level_records", message);
          break;

        case "reset":
          my_storage.reset("box_level_records", message);
          break;
      }
    }


    return {
      marked_can_level_records,
      box_level_message_handler,
      box_level_records,

    };
  },
};
</script>

<style scoped>
.mytitleclass {
  border-style: groove;
  /*background-color: black;
  color: white;*/
}
</style>