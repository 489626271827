<template>
  <!--<div class="backdrop" @click.self="$emit('close')">-->
  <div class="backdrop">
    <div class="modal">
      <div class="w3-animate-top">
        <div class="w3-container w3-teal ">
          <header class="w3-display-container">
            <span @click="$emit('close')" class="w3-button w3-display-topright">&times;</span>
            <h2 v-if="what == 'can_level'">알단위 투입</h2>
            <div v-else-if="what == 'box_level'">
              <h2>박스단위투입</h2>
              {{ summary.can }}
              {{ summary.coeffee }}
            </div>
            <div v-else>
              <h2>재료보충</h2>
            </div>
          </header>
        </div>

        <div class="w3-container">
          <div v-if="what=='can_level'">
            <form @submit.prevent="handleSubmit">
            <div v-if="what == 'reception_level'" class="items_on_the_same_line" style="margin-top:3px">
              <label>재료가져간날자</label>
              <input type="text" v-model="date_used" required />
            </div>

            <!-- can products 13 = 25 개의 캔음료-->

            <div class="can" style="margin-top:4px">
              캔음료
              <div v-if="can_entry.length">
                <div class="w3-container">
                  <div v-for="n in 6" :key="n">
                    <div class="w3-row">
                      <div v-for="i in 2" :key="i">
                        <div class="w3-col s3">
                          <!--<label>{{ 2 * (n - 1) + i }}</label>-->
                          <select class="option" v-model="can_entry[2 * (n - 1) + i - 1].name">
                            <option v-for="can in can_products" :key="can.name" :value="can.name" class="option">
                              {{ can.name }}
                            </option>
                          </select>
                        </div>
                        <div class="w3-col s2">
                          <input class="w3-input w3-border" style="height:12px" type="number"
                            v-model="can_entry[2 * (n - 1) + i - 1].quan" />
                        </div>
                        <div class="w3-col s1">
                          <span>&nbsp;</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <!-- coeffee products -->
            <div class="coeffee">
              커피재료
              <div v-if="coeffee_entry.length">
                <div class="w3-container">
                  <div v-for="n in 3" :key="n">
                    <div class="w3-row">
                      <div v-for="i in 2" :key="i">
                        <div class="w3-col s3">
                          <!--<label>{{ 2 * (n - 1) + i }}</label>-->
                          <select class="option" v-model="coeffee_entry[2 * (n - 1) + i - 1].name">
                            <option v-for="coeffee in coeffee_products" :key="coeffee.name" :value="coeffee.name"
                              class="option">
                              {{ coeffee.name }}
                            </option>
                          </select>
                        </div>
                        <div class="w3-col s2">
                          <input class="w3-input w3-border" style="height:12px" type="number"
                            v-model="coeffee_entry[2 * (n - 1) + i - 1].quan" />
                        </div>
                        <div class="w3-col s1">
                          <span>&nbsp;</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
              

          </div>
          <div v-else>
            <form @submit.prevent="handleSubmit">
            <div v-if="what == 'reception_level'" class="items_on_the_same_line" style="margin-top:3px">
              <label>재료가져간날자</label>
              <input type="text" v-model="date_used" required />
            </div>

            <!-- can products 13 = 25 개의 캔음료-->

            <div class="can" style="margin-top:4px">
              캔음료
              <div v-if="can_entry.length">
                <div class="w3-container">
                  <div v-for="n in 13" :key="n">
                    <div class="w3-row">
                      <div v-for="i in 2" :key="i">
                        <div class="w3-col s3">
                          <!--<label>{{ 2 * (n - 1) + i }}</label>-->
                          <select class="option" v-model="can_entry[2 * (n - 1) + i - 1].name">
                            <option v-for="can in can_products" :key="can.name" :value="can.name" class="option">
                              {{ can.name }}
                            </option>
                          </select>
                        </div>
                        <div class="w3-col s2">
                          <input class="w3-input w3-border" style="height:12px" type="number"
                            v-model="can_entry[2 * (n - 1) + i - 1].quan" />
                        </div>
                        <div class="w3-col s1">
                          <span>&nbsp;</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <!-- coeffee products -->
            <div class="coeffee">
              커피재료
              <div v-if="coeffee_entry.length">
                <div class="w3-container">
                  <div v-for="n in 5" :key="n">
                    <div class="w3-row">
                      <div v-for="i in 2" :key="i">
                        <div class="w3-col s3">
                          <!--<label>{{ 2 * (n - 1) + i }}</label>-->
                          <select class="option" v-model="coeffee_entry[2 * (n - 1) + i - 1].name">
                            <option v-for="coeffee in coeffee_products" :key="coeffee.name" :value="coeffee.name"
                              class="option">
                              {{ coeffee.name }}
                            </option>
                          </select>
                        </div>
                        <div class="w3-col s2">
                          <input class="w3-input w3-border" style="height:12px" type="number"
                            v-model="coeffee_entry[2 * (n - 1) + i - 1].quan" />
                        </div>
                        <div class="w3-col s1">
                          <span>&nbsp;</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>

          </div>

        </div>

        <footer class="w3-container w3-teal w3-display-container"
          style="margin-top:3px;padding-top:5px; padding-bottom:5px">
          <button @click="handleSubmit" class="w3-button">저장</button>
          <button @click="$emit('close')" class="w3-button">취소</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import time_utils from "@/composables/utils/time_utils.js";
import array_utils from "@/composables/utils/array_utils.js";
import { ref, computed } from "vue";
export default {
  props: ["mode", "current", "record", "what", "marked_can_level_records"],
  setup(props, ctx) {
    const {
      initialize_my_array,
      remove_zero_quan,
      replace_temp1,
      sum_of_can_coeffee_products,
      process_products_json_string,
    } = array_utils();
    const marked_can_level = computed(() => {
      // 동일거래처기록만 사용한다.
      if (props.what == "box_level")
        return props.marked_can_level_records.filter(
          (record) => props.record.client == record.client
        );
      else return [];
    });
    const summary = computed(() => {
      let temp = sum_of_can_coeffee_products(marked_can_level.value);
      //console.log("temp in summary in modal");
      //console.log(temp);
      let i,
        txt1 = "",
        txt2 = "";
      for (i = 0; i < temp.can.length; i++)
        txt1 = txt1 + temp.can[i].name + " " + temp.can[i].quan + ",";
      for (i = 0; i < temp.coeffee.length; i++)
        txt2 = txt2 + temp.coeffee[i].name + " " + temp.coeffee[i].quan + ",";

      return {
        can: txt1,
        coeffee: txt2,
      };
    });
    //console.log("current in setup in canlevel modal");
    //console.log(props.current);
    const { current_date } = time_utils();
    //const { parse_json } = json_utils();

    const can_products = computed(() => my_storage.get("can_products"));
    const coeffee_products = computed(() => my_storage.get("coeffee_products"));
    const can_entry_size = computed(() => my_storage.get("can_entry_size"));
    const coeffee_entry_size = computed(() =>
      my_storage.get("coeffee_entry_size")
    );
    // can_entry=[{name:'', quan:0}, ....]

    const date_used = ref("");
    if (props.what == "reception_level" && props.mode == "update") {
      //console.log("setup canlevelmodal.vue");
      //console.log(props.record);
      date_used.value = props.record.date_used;
    }
    // 캔 제품 input field를 생성하거나, record에서 가져온다.
    const can_entry = computed(() => {
      let temp1 = initialize_my_array(can_entry_size.value);
      if (props.mode == "update") {
        let temp2 = JSON.parse(props.record.can_products);
        temp1 = replace_temp1(temp1, temp2);
      }
      return temp1;
    });
    // 커피 제품 input field를 생성하거나, record에서 가져온다.
    const coeffee_entry = computed(() => {
      let temp1 = initialize_my_array(coeffee_entry_size.value);
      if (props.mode == "update") {
        let temp2 = JSON.parse(props.record.coeffee_products);
        temp1 = replace_temp1(temp1, temp2);
      }
      return temp1;
    });

    // 거래처를 반드시 선택하여야... 깡통단위의 투입기록을 작성할수 있다.
    if (props.what == "can_level") {
      if (props.current == "all" || props.current == "평택전자") {
        prompt("거래처를 선택하셔야 합니다. (단, 평택단자는 제외)");
        ctx.emit("close");
      }
    }
    if (props.what == "reception_level") {
      if (props.current !== "all") {
        prompt("전체를 선택하고 다시 하세요.");
        ctx.emit("close");
      }
    }

    function handleSubmit() {
      let id, temp_date_used, client;
      if (props.mode == "add") {
        id = "" + Date.now();
        if (props.what == "reception_level") temp_date_used = date_used.value;
        else temp_date_used = current_date();
        client = props.current;
      } else {
        id = props.record.id;
        if (props.what == "box_level") temp_date_used = "none";
        else if (props.what == "reception_level")
          temp_date_used = date_used.value;
        else temp_date_used = props.record.date_used;
        client = props.record.client;
      }

      //console.log(can_entry.value);
      //console.log(coeffee_entry.value);
      let message = {
        link_db: "yes",
        action: props.mode,
        params: {
          id: id,
          date_used: temp_date_used,
          client: client,
          // quan=0인 재료는 제거한후 stringify한다.
          can_products: JSON.stringify(remove_zero_quan(can_entry.value)),
          coeffee_products: JSON.stringify(
            remove_zero_quan(coeffee_entry.value)
          ),
        },
      };
      //console.log("modal message in modal.vue");
      //console.log(message);
      ctx.emit("message", message);
      ctx.emit("close");
    }

    function cancel_handle() {
      ctx.emit("close");
    }

    return {
      // input fields
      date_used,
      can_entry,
      coeffee_entry,

      // 제품목록 (select에서 사용하기 위함)
      can_products,
      coeffee_products,

      // handler
      handleSubmit,
      cancel_handle,

      marked_can_level,
      summary,
    };
  },
};
</script>

<style scoped>
/*
.modal {
  width: 90%;
  height: 1000px;
  padding: 10px;
  margin: 10px auto;
  background: white;
  border-radius: 10px;
  border-style: groove;
}
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
form {
  width: 500px;
  margin: 5px auto;
  background: white;
  text-align: left;
  padding: 5px;
  border-radius: 10px;
}
label {
  color: #aaa;
  display: inline-block;
  margin: 5px 0 5px;
  font-size: 1.1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input,
select {
  display: block;
  font-size: 14px;
  padding: 2px 2px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
input[type="checkbox"] {
  display: inline-block;
  font-size: 14px;
  width: 16px;
  margin: 0 10px 0 0;
  position: relative;
  top: 2px;
}

.option {
  font-size: 14px;
  padding: 5px;
}

.can {
  width: 45%;
  float: left;
  padding: 5px;
  border: 1px solid brown;
  border-radius: 15px;
  margin: 2px;
}
.margin_between {
  width: 3%;
  float: left;
  padding: 5px;
}
.coeffee {
  width: 45%;
  float: left;
  padding: 5px;
  border: 1px solid brown;
  border-radius: 15px;
  margin: 2px;
}*/
</style>
