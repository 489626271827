<template>
  <div class="product" :class="{ marked: record.check_field == '1' }">
    <!----- single record vue ---
    what = {{ what }}<br/>
    current={{ current }}<br/>
      record={{ record }}<br/>-->
    <!--{{ display_date(record.date_used) }}-->
    <div class="w3-row">
      <div class="my-w3-container w3-cell">
        <p class="product_name">
          ({{ record.client }}) - {{ display_date(record.date_used) }}
          {{ process_products_json_string(record.can_products) }}
          {{ process_products_json_string(record.coeffee_products) }}
        </p>
      </div>
      <div class="my-w3-container w3-cell" style="width:20%">
        <div class="icons">
          <span v-if="what == 'can_level' ||
    what == 'box_level' ||
    (what == 'reception_level' && record.client == 'all')
    " @click="showEditRecord = true" class="w3-badge w3-green w3-hover-red" style="margin-right:5px">߾</span>
          <!-- can_level_record를 삭제하는 것은 허용치 않음-->
          <span v-if="what != 'reception_level'" @click="create_message('delete')"
            class="w3-badge w3-green w3-hover-red" style="margin-right:5px">Х</span>
          <!--          <span v-if="what != 'reception_level'" @click="create_message('delete')"
            class="w3-badge w3-green w3-hover-red" style="fixed: relative; right: 10px; margin-right:5px">x</span>-->
          <span v-if="what == 'can_level' ||
    (what == 'reception_level' && record.client == 'all')
    " :class="{ marked: record.check_field == '1' }" @click="create_message('mark')"
            class="w3-badge w3-green w3-hover-red" style="margin-right: 0px">߇</span>
        </div>
      </div>
    </div>

    <!--
      modal for editing can or box level
    -->
  </div>
  <div v-if="showEditRecord">
    <RefillModal mode="update" :what="what" :record="record" :current="current"
      :marked_can_level_records="marked_can_level_records" @message="message_handler" @close="showEditRecord = false">
    </RefillModal>
  </div>
</template>

<script>
import RefillModal from "@/views/refill_management/modals/RefillModal.vue";
import time_utils from "@/composables/utils/time_utils.js";
import json_utils from "@/composables/utils/json_utils.js";
import { ref, computed } from "vue";
export default {
  props: ["what", "record", "current", "marked_can_level_records"],
  components: { RefillModal },
  setup(props, ctx) {
    const showEditRecord = ref(false);
    /*const { put_products_db_api } =
      put_products_db_access();*/

    const { display_date } = time_utils();
    const { process_products_json_string } = json_utils();

    //console.log('can_products in singlerecord.vue')
    //console.log(props.can_products)
    function create_message(what) {
      let message, check_field;
      switch (what) {
        case "delete":
          if (confirm('삭제하시겠습니까?')) {
            alert("삭제되었습니다")
          } else {
            alert('삭제가 취소되었습니다.')
            return
          }
          message = {
            link_db: "yes",
            action: "delete",
            params: {
              id: props.record.id,
            },
          };
          break;

        case "mark":
          //console.log("check_field=");
          //console.log(props.record.check_field);
          if (props.record.check_field == "0") check_field = "1";
          else check_field = "0";
          message = {
            link_db: "no",
            action: "mark",
            params: {
              id: props.record.id,
              check_field: check_field,
            },
          };
          break;
      }
      ctx.emit("message", message);
    }

    // emit from the modal.. just pass to the parent
    function message_handler(message) {
      ctx.emit("message", message);
    }

    return {
      // delete or mark 에 대한 처리
      create_message,

      // update 에 대한 처리
      message_handler,

      // modal flag
      showEditRecord,

      // utils
      display_date,
      process_products_json_string,
    };
  },
};
</script>

<style scoped>
.product {
  margin: 2px 5px 2px 5px;
  background: white;
  padding: 2px 5px 2px 5px;
  border-radius: 4px;
  border-style: ridge;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  border-left: 8px solid green;
}

.product.marked {
  border-left: 8px solid red;
}

.product.marked .tick {
  color: red;
}

.material-symbols-outlined {
  font-size: 24px;
  margin-left: 10px;
  color: #bbb;
  cursor: pointer;
}

.material-symbols-outlined:hover {
  color: #777;
}

p {
  margin: 1px;
  padding: 1px;
}

.marked {
  color: red;
}
</style>