<template>
  <div class="w3-container">
    <div class="w3-container w3-border">
      <div class="w3-cell-row">
        <p class="w3-cell w3-left">컬럼정보</p>
        <button class="w3-button w3-cel w3-right" @click="showSlotInfoModal = true">
          수정하기
        </button>
      </div>

      <div v-if="can_slots.length >= 1">
        <div v-for="can_line in can_slots" :key="can_line">
          <div class="w3-row">
            <span v-for="can in can_line" :key="can" class="w3-col s4">
              {{ can }}
            </span>
          </div>
        </div>
      </div>
      <div v-if="coeffee_slots.length >= 1">
        <div v-for="coeffee_line in coeffee_slots" :key="coeffee_line">
          <div class="w3-row">
            <span v-for="coeffee in coeffee_line" :key="coeffee" class="w3-col s4">
              {{ coeffee }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showSlotInfoModal">
    <SlotInfoModal :machine="machine" @message="message_handler" @close="showSlotInfoModal = false">
    </SlotInfoModal>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import array_utils from "@/composables/utils/array_utils.js";
import SlotInfoModal from "@/views/machine_management/modals/SlotInfoModal.vue";
export default {
  props: ["machine"],
  components: { SlotInfoModal },
  setup(props, ctx) {
    const { products_line } = array_utils();

    //setup(props, context) {
    const showSlotInfoModal = ref(false);
    const can_slots = computed(() => {
      return products_line({ n_slots: props.machine.n_can_slots, slots: props.machine.can_slots })
    });
    const coeffee_slots = computed(() => {
      return products_line({ n_slots: props.machine.n_coeffee_slots, slots: props.machine.coeffee_slots })
    });
    /*    const coeffee_slots = computed(() =>
          props.machine.coeffee_slots.split("/")
        );*/

    //console.log('slotinfo')
    //console.log(props.can_products)
    //console.log(props.coeffee_products)

    function message_handler(message) {
      //machine_db_api( {'action': 'update_basic_info', 'body': { new_basic_info }})
      //console.log("slot info update");
      //console.log(updated_machine);
      ctx.emit("message", message);
    }

    return {
      showSlotInfoModal,
      can_slots,
      coeffee_slots,

      message_handler,
    };
  },

  /*data() {
          return {
              showDetails: false
          }
      },
      methods: {
          toggleShowDetails() {
              this.showDetails = !this.showDetails
          },
          deleteProject() {
              const body = {'action': 'delete', 'body': {id: this.project.id}}
              const option = {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
              fetch('delete_a_project.php', option)
                  .then(() => this.$emit('delete', this.project.id))
                  .catch(err => console.log(err.message))
          },
          editProject() {
              const body = {'action': 'edit', 'body': {id: this.project.id}}
              const option = {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
              fetch('get_a_project.php', option)
              .then( res => res.json())
                  .then(data => console.log(data))
                  .catch(err => console.log(err.message))
          },
          toggleComplete() {
              // 0 == false  1 == true
              let temp_complete = 0;
              if (this.project.complete) temp_complete = 1;
              const body = {'action': 'update', 'body': {id: this.project.id, complete: temp_complete}}
              const option = {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
              fetch('update_complete_status.php', option)
                  .then(() => this.$emit('toggle_complete', this.project.id))
                  .catch(err => console.log(err.message))
  
          }
      }*/
};
</script>

<style scoped>
.grid_container_for_slot {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 1px;
  background-color: lightblue;
  padding: 1px;
}

.subtitle {
  grid-column: 1 / span 5;
  grid-row: 1;
}
</style>
