<template>
  <div class="w3-container">
    <div class="w3-container w3-border">
      <div class="w3-cell-row">
        <p class="w3-cell w3-left">카운타</p>
        <p class="w3-cell w3-left">&nbsp;&nbsp;</p>
        <p class="w3-cell w3-right">이전카운타날자: {{ counter_date }}</p>
        <p class="w3-button w3-cell w3-right" @click="showEditCounterModal = true">
          수정하기
        </p>
      </div>
      <div v-if="counter.length >= 1">
        <div v-for="line in counter" :key="line">
          <div class="w3-row">
            <span v-for="counter in line" :key="counter" class="w3-col s4">
              {{ counter }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-if="showEditCounterModal">
    <CounterModal :machine="machine" @message="message_handler" @close="showEditCounterModal = false">
    </CounterModal>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import array_utils from "@/composables/utils/array_utils.js";
import counter_utils from "@/composables/utils/counter_utils.js";
import CounterModal from "@/views/machine_management/modals/CounterModal.vue";
export default {
  props: ["machine"],
  components: { CounterModal },
  setup(props, ctx) {
    const { counter_line } = array_utils();

    const { counter_value } = counter_utils();


    //setup(props, context) {
    const showEditCounterModal = ref(false);
    const counter = computed(() => {
      //console.log('hgkim')
      //console.log(props.machine.counter)
      //return JSON.parse(props.machine.counter);
      console.log('counter')
      console.log(props.machine.counter)
      return counter_line({ n_slots: 18, slots: JSON.parse(props.machine.counter).join('/') })
    });
    const counter_date = computed(() => props.machine.counter_date);

    function message_handler(message) {
      //machine_db_api( {'action': 'update_basic_info', 'body': { new_basic_info }})
      //console.log('slot info update')
      //console.log(new_slot_info)
      ctx.emit("message", message);
      ctx.emit("close");
    }

    return {
      counter_value,

      showEditCounterModal,
      counter,
      counter_date,

      message_handler,
    };
  },
};
</script>

<style>
/*.project {
  margin: 20px auto;
  background: white;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  border-left: 4px solid #e90074;
}
h3 {
  cursor: pointer;
}
.grid_container_for_counter {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 1px;
  background-color: lightblue;
  padding: 1px;
}
.subtitle {
  grid-column: 1 / span 5;
  grid-row: 1;
}*/
</style>
