<template>
    <button class="btn info" @click="calculateProductReception">
      평택단자계산
    </button>
</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import array_utils from "@/composables/utils/array_utils.js";
import { ref, computed } from "vue";

export default {
  components: {
  },
  setup() {

    // 사무소에서 가져온 상품 기록중에 마킹된 기록
    const marked_products_reception_record = computed(() => {
      return products_reception_records.value.filter(
        (record) => record.check_field == "1"
      );
    });

    const box_level_records = computed(() => {
      let temp = my_storage.get("box_level_records");
      return temp;
    });

    const products_reception_records = computed(() =>
      my_storage.get("products_reception_records")
    );

    /*
      평택단자 line: ["펩시 3, 사이다 3, 쌕쌕 3, "]
    */

    // 평택단자 박스 투입 기록 계산한것
    let PT_box_reception = { can: [], coeffee: [] };

    const {
      sum_of_same_key_values,
      sum_products,
      negate_quantity2,
      remove_zero_quan,
      sum_of_products,
      format_for_processing
    } = array_utils();

    function calculateProductReception() {

      if (marked_products_reception_record.value.length != 1) {
        prompt("사무실서 가져온 재료 기록 중 반드시 1개를 선택후 진행하세요.");
        return;
      }
      if (confirm('평택단자 계산을 진행하면 과거 동일일자의 재료투입기록은 replace됩니다. 진행하겠습니까?' )) {

      } else {
        return
      }


      // pass converted box_level_records
      PT_box_reception = calculatePtReception(format_for_processing(box_level_records.value));

      // save into the db as well
      // 산출된 평택단자의 기록을 box_level_products_refill에 저장한다.
      let temp = {
        action: "update",
        params: {
          client: "평택단자",
          can_products: JSON.stringify(PT_box_reception.can),
          coeffee_products: JSON.stringify(PT_box_reception.coeffee),
        },
      };
      my_storage.fetch_box_level(temp); // save in mysql
      my_storage.update("box_level_records", temp); // update in box_level_records
      //my_storage.updatePT(temp)

      //
      // step 3.  all을 제외한 모든거래처의 기록을 products_reception에 저장한다.
      //    box_level_records의 각각은 각각 거래처별로 저장한다.
      //    date_received = same as all
      //    client=각각의 거래처이름
      //    can_products.... [{"name":"펩시","quan":2},{.....}, ...]
      //    coeffee_products.... [{"name":"펩시","quan":2},{.....}, ...]
      let mytemp, i;
      for (i = 0; i < box_level_records.value.length; i++) {
        if (box_level_records.value[i].client !== "all") {
          mytemp = {
            action: "add",
            params: {
              id: "" + Date.now() + box_level_records.value[i].client,
              client: box_level_records.value[i].client,
              can_products: box_level_records.value[i].can_products,
              coeffee_products: box_level_records.value[i].coeffee_products,
              date_used: marked_products_reception_record.value[0].date_used,
            },
          };
          my_storage.fetch_products_reception(mytemp);
          my_storage.add("products_reception_records", mytemp);
        }
      }
    }

    /*
      db format
      date_used: '240101'
      client: '평택단자'
      can_products: string '[{name:'펩시', quan:0}, ...]'
      coeffee_products: string '[{name:'펩시', quan:0}, ...]'

      처리과정의 format
      date_used: '240101'
      client: '평택단자'
      can: [{name:'펩시', quan:0}, ...]
      coeffee: [{name:'펩시', quan:0}, ...]
    */

    function calculatePtReception(formated_records) {

      // box_level_records 에서 평택을 제외한 거래처의 
      // 캔 / 커피 재료 갯수를 모두합한다/
      // can: [{name:"펩시", quan:sumof 펩시}, ....]
      // coeffee: [{name:"화인", quan:sumof 화인}, ....]
      // 결과치는 

      //console.log('formated_records in pentackreception.vue calculateptrece')
      //console.log(formated_records)
      let sum={can:[], coeffee:[]}, other
      other = formated_records.filter((item)=>item.client!='평택단자')
      sum.can = sum_of_products(other, 'can')
      sum.coeffee = sum_of_products(other, 'coeffee')

      //console.log("sum of box reception ");
      //console.log(sum);

      let negative_sum={can:[], coeffee:[]}
      negative_sum.can = negate_quantity2(sum.can); // 갯수에 -를 곱한 결과 (총합에서 뺄셈하기 위함)
      negative_sum.coeffee = negate_quantity2(sum.coeffee); // 갯수에 -를 곱한 결과 (총합에서 뺄셈하기 위함)
      //console.log(negative_sum.can)
      //console.log(negative_sum.coeffee)
      negative_sum.can = negative_sum.can.concat(JSON.parse(marked_products_reception_record.value[0].can_products)); // - 기록과 총합기록을 합함
      negative_sum.coeffee = negative_sum.coeffee.concat(JSON.parse(marked_products_reception_record.value[0].coeffee_products)); // - 기록과 총합기록을 합함

      let pt={can:[], coeffee:[]}
      pt.can = sum_of_same_key_values(negative_sum.can);
      pt.can = remove_zero_quan(pt.can);
      pt.coeffee = sum_of_same_key_values(negative_sum.coeffee);
      pt.coeffee = remove_zero_quan(pt.coeffee);
      //console.log('평택단자')
      //console.log(pt.can)
      //console.log(pt.coeffee)

      return pt;
    }


    return {
      // modals
      // 사무실서 가져온 상품 등록하는 모달
      box_level_records,
      products_reception_records,

      calculateProductReception,

    };
  },
};
</script>

<style scoped>
.grid_container {
  display: grid;
  grid-template-columns: 12% auto 12% auto 12% auto;
  grid-gap: 1px;
  background-color: lightblue;
  padding: 1px;
}
.subtitle {
  grid-column: 1 / span 6;
  grid-row: 1;
}
</style>