<template>
  <!--<p @click="showDetails = !showDetails">{{ machine.name }}</p>-->
  <div class="w3-container">
    <p class="project w3-hover-yellow" style="margin:1px" @click="showDetails = !showDetails">{{ machine.name }}</p>
  </div>
  <div v-if="showDetails">
    <BasicInfo :machine="machine" @message="message_handler">
    </BasicInfo>
    <br>
    <SlotInfo :machine="machine" @message="message_handler">
    </SlotInfo>
    <br>
    <CounterInfo :machine="machine" @message="message_handler">
    </CounterInfo>
  </div>

  <!--  slot  참조 정보임.
                <MachineModal>  ..내용..   </MachineModal>
                상기에 적용된 내용이 child component에 slot에 삽입된다.
                아래의 예에서 ninja    grap이 삽입됨.
                    
                named slot 사용방법
                <template v-slot:links>
                    <a href="#">sign up</a>
                    <a href="#">more info</a>
                </template>
-->
  <!--
            <CounterInfo
              :machine="machine"
              @update="updateCounterInfo"
            >
            </CounterInfo>

        </div>-->
</template>

<script>
import { computed, ref } from "vue";
import BasicInfo from "@/views/machine_management/display_machine/BasicInfo.vue";
import SlotInfo from "@/views/machine_management/display_machine/SlotInfo.vue";
import CounterInfo from "@/views/machine_management/display_machine/CounterInfo.vue";
export default {
  props: ["machine",],
  components: { BasicInfo, SlotInfo, CounterInfo },
  setup(props, ctx) {
    //setup(props, context) {
    //const can_slots = computed(() => props.machine.can_slots.split("/"));
    //const coeffee_slots = computed(() => props.machine.coeffee_slots.split("/"));

    const showDetails = ref(false);

    function message_handler(message) {
      console.log("message in dispolay machine.vue");
      console.log(message);
      ctx.emit("message", message);
    }

    return {
      showDetails,

      message_handler,
    };
  },
};
</script>

<style scoped>
.project {
  /*margin: 20px auto;*/
  margin: 2px 2px 2px 2px;
  background: white;
  /*padding: 10px 20px;*/
  padding: 2px 2px 2px 2px;
  border-radius: 4px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  border-left: 4px solid #e90074;
}

.project.complete {
  border-left: 4px solid #00ce89;
}

.project.complete .tick {
  color: #00ce89;
}
</style>