<template>
  <div class="my-w3-container">
    <button class="w3-button" @click="showModal_flag = true">상품추가</button>
  </div>


  <div v-if="products.length">
    <div class="w3-container">
      <slot name="modal_title"></slot>

      <div class="w3-container w3-border">
        <div v-for="n in Math.floor(products.length / 2)" :key="n">
          <div class="w3-row" style="margin-top:5px">
            <div class="w3-col s5">
              <div class="w3-cell-row">
                <div class="w3-cell">
                  <span>{{ products[2 * (n - 1)].name }}</span>
                </div>
                <div class="w3-cell" style="width:50%">
                  <span @click="click_handler(products[2 * (n - 1)])" class="w3-badge w3-green w3-hover-red">߾</span>
                  &nbsp;<span @click="delete_handler(products[2 * (n - 1)])"
                    class="w3-badge w3-green w3-hover-red">Х</span>
                </div>
              </div>
            </div>

            <div class="w3-col s1">&nbsp;</div>

            <div class="w3-col s5">
              <div class="w3-cell-row">
                <div class="w3-cell">
                  <span>{{ products[2 * (n - 1) + 1].name }}</span>
                </div>
                <div class="w3-cell icons" style="width:50%">
                  <span @click="click_handler(products[2 * (n - 1) + 1])"
                    class="w3-badge w3-green w3-hover-red">߾</span>
                  &nbsp;<span @click="delete_handler(products[2 * (n - 1) + 1])"
                    class="w3-badge w3-green w3-hover-red">Х</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 짝수가 아닌경우에 남은 것 하나 처리 -->
        <div v-if="products.length % 2 == 1">
          <div class="w3-row" style="margin-top:5px">
            <div class="w3-col s5">
              <div class="w3-cell-row">
                <div class="w3-cell">
                  <span>{{ products[products.length - 1] }}</span>
                </div>
                <div class="w3-cell" style="width:50%">
                  <span @click="click_handler(products[products.length - 1])"
                    class="w3-badge w3-green w3-hover-red">߾</span>
                  &nbsp;<span @click="delete_handler(products[products.length - 1])"
                    class="w3-badge w3-green w3-hover-red">Х</span>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div v-else>Loading</div>
  <div v-if="showModal_flag">
    <ProductModal :mode="mode" act="add" :clients="clients" :products="products" @message="message_handler"
      @close="showModal_flag = false">
    </ProductModal>
  </div>
  <div v-if="showEditRecord">
    <ProductModal :mode="mode" act="update" :products="products" :product="product" @message="message_handler"
      @close="showEditRecord = false">
    </ProductModal>
  </div>
</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import ProductModal from "@/views/product_management/modals/ProductModal.vue";
import { computed, ref } from "vue";
export default {
  name: "ProducManagement",
  props: ["mode",],
  components: { ProductModal },
  setup(props) {
    const can_products = computed(() => my_storage.get("can_products"));
    const coeffee_products = computed(() => my_storage.get("coeffee_products"));

    const product = ref({})

    const products = computed(() => {
      let myarray = []
      if (props.mode == "can") {
        console.log("canproducts.vue");
        console.log(can_products.value);
        let remainder = can_products.value.length % 2
        myarray = can_products.value
        if (remainder == 1) myarray.push("")
        return myarray
      } else {
        console.log("coeffee products.vue");
        console.log(coeffee_products.value);
        let remainder = coeffee_products.value.length % 2
        myarray = coeffee_products.value
        if (remainder == 1) myarray.push("")
        return myarray;
      }
    });

    const showModal_flag = ref(false);
    const showEditRecord = ref(false);

    function print(price) {
      let temp_array = JSON.parse(price)
      let i, temp_string = ""
      for (i = 0; i < temp_array.length; i++) {
        temp_string += (temp_array[i].client + ' ' + temp_array[i].price + ', ')
      }
      return temp_string
    }

    function click_handler(pass) {
      console.log('product in click handler in myproducts.vue')
      console.log(pass)
      product.value = pass
      showEditRecord.value = true
    }

    function message_handler(message) {
      switch (props.mode) {
        case "can":
          my_storage.fetch_can_products(message);
          switch (message.action) {
            case "add":
              my_storage.add("can_products", message);
              break;
            case "update":
              my_storage.update("can_products", message);
              break;
          }
          break;

        case "coeffee":
          my_storage.fetch_coeffee_products(message);
          switch (message.action) {
            case "add":
              my_storage.add("coeffee_products", message);
              break;
            case "update":
              my_storage.update("coeffee_products", message);
              break;
          }
          break;
      }
    }

    function delete_handler(product) {
      if (confirm(product.name + "을 삭제하겠습니까?")) {
        let body = {
          action: "delete",
          params: { id: product.id },
        };
        if (props.mode == "can") {
          my_storage.fetch_can_products(body);
          my_storage.delete("can_products", body);
        } else {
          my_storage.fetch_coeffee_products(body);
          my_storage.delete("coeffee_products", body);
        }
        alert("삭제되었습니다.");
      } else {
        alert("취소되었습니다");
        return;
      }
    }

    return {
      products, product,
      showModal_flag,
      showEditRecord,
      message_handler, click_handler,
      delete_handler, print
    };
  },
};
</script>

<style scoped>
.my-span:hover {
  color: #777;
}

.product_name {
  margin: 2px;
  display: inline;
}

/*.product {
  margin: 1px auto;
  background: white;
  padding: 2px;
}*/

.product {
  margin: 2px 5px 2px 5px;
  background: white;
  padding: 2px 5px 2px 5px;
  border-radius: 4px;
  border-style: ridge;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.05);
  border-left: 8px solid green;
}

.material-symbols-outlined {
  font-size: 12px;
  margin-left: 10px;
  color: #bbb;
  cursor: pointer;
}

.material-symbols-outlined:hover {
  color: #777;
}
</style>