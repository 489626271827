const json_utils = () => {


  const process_products_json_string = (json_string) => {
    let mylength,
      i,
      temp = [],
      tempstr = "";
    temp = JSON.parse(json_string);
    mylength = temp.length;

    for (i = 0; i < mylength; i++) {
      if (temp[i].name != "") {
        tempstr = tempstr + temp[i].name + " " + temp[i].quan + ",";
      }
    }
    return tempstr;
  }
  
  const parse_json = (json_string) => {
    let mylength,
      i,
      temp = [],
      tempstr = "";
    temp = JSON.parse(json_string);
    mylength = temp.length;

    for (i = 0; i < mylength; i++) {
      if (temp[i].name != "") {
        tempstr = tempstr + temp[i].name + " " + temp[i].quan + ",";
      }
    }
    return tempstr;
  }

  return { process_products_json_string, parse_json, }
}

export default json_utils
