<template>
  <div class="w3-container">
    <div class="w3-row">
      <h2 class="w3-col s10">{{ mytitle }}</h2>
    </div>
    <div class="w3-row">
      <button @click="updateFilter('all')" class="w3-col" style="width:9%" :class="{ active: current == 'all' }">
        전체
      </button>
      <div v-for="client in clients" :key="client.name">
        <button @click="updateFilter(client.name)" class="w3-col" style="width:18%"
          :class="{ active: current == client.name }">
          {{ client.name }}
        </button>
      </div>
      <p style="height:5px">&nbsp;</p>
    </div>
  </div>

  <!--
  <nav class="items_on_the_same_line">
    <button 
        @click="updateFilter('all')" 
        class="w3-cell"
        :class="{ active: current == 'all' }"
    >
      전체
    </button>
    <div v-for="client in clients" :key="client.name">
      <button
        @click="updateFilter(client.name)"
        class="nav_button"
        :class="{ active: current == client.name }"
      >
        {{ client.name }}
      </button>
    </div>
  </nav>-->
</template>

<script>
export default {
  props: ["current", "clients", "mytitle"],
  methods: {
    updateFilter(by) {
      //console.log("by");
      //console.log(by);
      this.$emit("filterChange", by);
    },
  },
};
</script>

<style scoped>
.active {
  color: red;
}
</style>