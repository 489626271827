<template>
  <div class="my-w3-container">
    <div class="w3-display-container w3-green" style="height:60px">
      <div class="w3-cell-row">
        <div class="w3-display-left">
          <div style="width:70%">
            <div class="w3-container w3-green w3-cell" style="width:70%">
              <h2 class="w3-cell">상품관리</h2>
            </div>
          </div>
        </div>
        <div class="w3-display-right">
          <div class="w3-container w3-green w3-cell">
            <p style="margin-left:20px;"><input class="w3-radio" type="radio" name="gender" value="can" v-model="picked"
                checked>
              <label>캔음료</label>&nbsp;&nbsp;
              <input class="w3-radio" type="radio" name="gender" value="coeffee" v-model="picked">
              <label>커피재료</label>&nbsp;&nbsp;
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <my-products :mode="picked">
  </my-products>
</template>

<script>
import { ref } from 'vue'
import MyProducts from './MyProducts.vue';
export default {
  name: "ProducManagement",
  components: { MyProducts },
  setup() {
    const picked = ref("can")
    return {
      picked,
    }
  }
};
</script>

<style scoped>
.can {
  width: 100%;
  float: left;
  padding: 5px;
  border: 1px solid brown;
  border-radius: 15px;
}

.margin_between {
  width: 3%;
  float: left;
  padding: 5px;
}

.coeffee {
  width: 100%;
  float: left;
  padding: 5px;
  border: 1px solid brown;
  border-radius: 15px;
}
</style>