<template>
  <div class="backdrop">
    <div class="modal">
      <div class="w3-animate-top">
        <div class="w3-container w3-teal">
          <header class="w3-display-container">
            <span @click="$emit('close')" class="w3-button w3-display-topright">&times;</span>
            <h2 v-if="mode == 'add'">기계 등록하기</h2>
            <h2 v-else>기계 수정하기</h2>
          </header>
        </div>

        <div class="w3-container" style="margin-top:4px">

          <div style="margin-bottom: 1px;">
            <form @submit.prevent="handleSubmit">
            <p>
              <!--<label class="w3-text-teal"><b>client</b></label>-->
              <select class="w3-select" v-model="client_name" required>
                <option value="" disabled selected>Choose your option</option>
                <option v-for="client in clients" :key="client.name" :value="client.name">
                  {{ client.name }}
                </option>
              </select>
            </p>

            <p>
              <!--<label class="w3-text-teal"><b>location</b></label>-->
              <input class="w3-input" type="text" required v-model="location" />
            </p>

            <p>
              <!--<label class="w3-text-teal"><b>기계 이름</b></label>-->
              <input class="w3-input" type="text" required v-model="name" />
            </p>

            <p>
              <label class="w3-text-teal"><b>Machine Number</b></label>
              <input class="w3-input" type="text" required v-model="machine_number" />
            </p>

            <p>
              <label class="w3-text-teal"><b>카드단말번호</b></label>
              <input class="w3-input" type="text" required v-model="card" />
            </p>

            <p>
              <label class="w3-text-teal"><b>기계종류</b></label>
              <select class="w3-select" v-model="kind" required>
                <option value="" disabled selected>Choose your option</option>
                <option value="캔">캔</option>
                <option value="커피">커피</option>
                <option value="겸용">겸용</option>
              </select>
            </p>
            <p>
              <label class="w3-text-teal"><b>캔 슬롯 갯수</b></label>
              <input class="w3-input" type="number" required v-model="n_can_slots" />
            </p>
            <p>
              <label class="w3-text-teal"><b>커피 슬롯 갯수</b></label>
              <input class="w3-input" type="number" required v-model="n_coeffee_slots" />
            </p>
          </form>

          </div>
        </div>

        <footer class="my-w3-container w3-teal w3-display-container"
          style="margin-top:3px;padding-top:5px; padding-bottom:5px">
          <button @click="handleSubmit" class="w3-button">저장</button>
          <button @click="$emit('close')" class="w3-button">취소</button>
        </footer>

      </div>


      <!--default slot
          <slot>default content</slot>
          parent에서 slot을 지정하여 불렀으면 parent가 지정한 내용이 여기에 삽입됨
          parent에서 slot 정보를 지정하지 않으면...  default content가 사용된다
          별로 용도는 없음.
        -->
      <!--  named slot ... SingleMachine.vue를 보라...
        <div class="actions">
         <slot name="links"></slot>
        </div>
        -->
    </div>
  </div>
</template>

<script>
//import {props} from 'vue'
import { my_storage } from "@/classes/my_storage.js";
import { ref, computed } from "vue";
import time_utils from "@/composables/utils/time_utils.js";
export default {
  props: ["machine", "mode"],
  setup(props, ctx) {

    const clients = computed(() => my_storage.get("clients"));
    const { current_date } = time_utils();


    const machine_number = ref("");
    const card = ref("");
    const client_name = ref("");
    const location = ref("");
    const name = ref("");
    const kind = ref("");
    const n_can_slots = ref(0);
    const n_coeffee_slots = ref(0);

    // edit mode인 경우 machine을 props로 전달받는다.
    if (props.mode == "update") {
      machine_number.value = props.machine.machine_number;
      card.value = props.machine.card_terminal_number;
      client_name.value = props.machine.client;
      location.value = props.machine.location;
      name.value = props.machine.name;
      kind.value = props.machine.kind;
      n_can_slots.value = props.machine.n_can_slots;
      n_coeffee_slots.value = props.machine.n_coeffee_slots;
    }

    //console.log("clients in basicinfomodal.vue");
    //console.log(props.clients);

    function handleSubmit() {
      let id, can_slots, coeffee_slots, counter, counter_date
      if (props.mode == "update") {
        id = props.machine.id;
        can_slots = props.machine.can_slots;
        coeffee_slots = props.machine.coeffee_slots;
        counter = props.machine.counter;
        counter_date = props.machine.counter_date;
      } else {
        id = "" + Date.now();
        can_slots = '/////////////////////////////////'
        coeffee_slots = '/////////////////////////////////'
        counter = '[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0]'
        counter_date = current_date()
      }
      let message = {
        action: props.mode,
        params: {
          // basic info의 add or update 처리
          client: client_name.value,
          name: name.value,
          location: location.value,
          machine_number: machine_number.value,
          card_terminal_number: card.value,
          kind: kind.value,
          n_can_slots: n_can_slots.value,
          n_coeffee_slots: n_coeffee_slots.value,
          // basic_info add / update의 경우 
          // can_slots, coeffee_slots 정보는 
          //   add의 경우는 ""로,
          //   update의 경우는 props의 값을 대치한다.
          id: id,
          can_slots: can_slots,
          coeffee_slots: coeffee_slots,
          counter: counter,
          counter_date: counter_date,
        },
      };
      console.log("machine info in basicinfomodal.vue");
      console.log(message);

      ctx.emit("message", message);
      //ctx.emit("add", machine_info);
      ctx.emit("close");
    }

    return {

      clients,

      machine_number,
      card,
      name,
      client_name,
      location,
      kind,
      n_can_slots,
      n_coeffee_slots,

      handleSubmit,
    };
  },
};
</script>

<style scoped>
/*
.modal {
  width: 400px;
  padding: 10px;
  margin: 50px auto;
  background: white;
  border-radius: 10px;
}
.backdrop {
  top: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
}
form {
  max-width: 420px;
  margin: 30px auto;
  background: white;
  text-align: left;
  padding: 40px;
  border-radius: 10px;
}
label {
  color: #aaa;
  display: inline-block;
  margin: 5px 0 5px;
  font-size: 0.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
}
input,
select {
  display: block;
  padding: 5px 3px;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-bottom: 1px solid #ddd;
  color: #555;
}
input[type="checkbox"] {
  display: inline-block;
  width: 16px;
  margin: 0 3px 0 0;
  position: relative;
  top: 2px;
}*/
</style>
