<template>
  <!--
            자판기상세
            장비번호:            거래처: 평택단자  위치:흡연실
            종류: 캔, 슬롯갯수:16, 
            슬롯정보
            # of slots : 16
            1.펩시 2.사이다 3.타임라떼 4.가나초코 5.쌕썍오렌지
            1.펩시 2.사이다 3.타임라떼 4.가나초코 5.쌕썍오렌지
            1.펩시 2.사이다 3.타임라떼 4.가나초코 5.쌕썍오렌지
            1.펩시 2.사이다 3.타임라떼 4.가나초코 5.쌕썍오렌지
            1.펩시 2.사이다 3.타임라떼 4.가나초코 5.쌕썍오렌지
            1.펩시 2.사이다 3.타임라떼 4.가나초코 5.쌕썍오렌지

            EDIT 기계

            최종카운터 24년1월15일
            1)   2) 400 300)400 400)    500)     600) 
            700)400 800)400 900)    1000)     1500) 

            카운터입력하기    이전카운터보기

        -->
  <div class="w3-container">
    <div class="w3-container w3-border">
      <div class="w3-cell-row">
        <p class="w3-cell w3-left">기본정보</p>
        <button class="w3-button w3-cel w3-right" @click="showEditBasicInfoModal = true">
          수정하기
        </button>
        <button class="w3-button w3-cell w3-right" @click="deleteHandler">삭제하기</button>
      </div>

      <div class="w3-row">
        <span class="w3-col s2">장비:</span>
        <span class="w3-col s4">{{ machine.machine_number }}</span>
        <span class="w3-col s2">카드:</span>
        <span class="w3-col s4">{{ machine.card_terminal_number }}</span>
      </div>
      <div class="w3-row">
        <span class="w3-col s2">거래처:</span>
        <span class="w3-col s4">{{ machine.client }}</span>
        <span class="w3-col s2">장소:</span>
        <span class="w3-col s4">{{ machine.location }}</span>
      </div>
      <div class="w3-row">
        <span class="w3-col s2">이름:</span>
        <span class="w3-col s4">{{ machine.name }}</span>
        <span class="w3-col s2">종류:</span>
        <span class="w3-col s4">{{ machine.kind }}</span>
      </div>
      <div class="w3-row">
        <span class="w3-col s2">캔:</span>
        <span class="w3-col s4">{{ machine.n_can_slots }}</span>
        <span class="w3-col s2">커피:</span>
        <span class="w3-col s4">{{ machine.n_coeffee_slots }}</span>
      </div>

    </div>

  </div>

  <div v-if="showEditBasicInfoModal">
    <BasicInfoModal mode="update" :machine="machine" @message="message_handler" @close="showEditBasicInfoModal = false">
    </BasicInfoModal>
  </div>
</template>

<script>
import { ref } from "vue";
import BasicInfoModal from "@/views/machine_management/modals/BasicInfoModal.vue";

export default {
  props: ["machine"],
  components: {
    BasicInfoModal,
  },
  setup(props, ctx) {
    const showEditBasicInfoModal = ref(false);

    function message_handler(message) {
      ctx.emit("message", message);
    }

    function deleteHandler() {
      if (confirm(props.machine.name + "을 삭제하겠습니까?")) {
        let message = {
          action: "delete",
          params: {
            id: props.machine.id,
          },
        };
        ctx.emit("message", message);
      } else {
        confirm("취소되었습니다.")
      }
    }

    return {
      showEditBasicInfoModal,

      message_handler,

      //update,
      deleteHandler,
    };
  },

  /*data() {
          return {
              showDetails: false
          }
      },
      methods: {
          toggleShowDetails() {
              this.showDetails = !this.showDetails
          },
          deleteProject() {
              const body = {'action': 'delete', 'body': {id: this.project.id}}
              const option = {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
              fetch('delete_a_project.php', option)
                  .then(() => this.$emit('delete', this.project.id))
                  .catch(err => console.log(err.message))
          },
          editProject() {
              const body = {'action': 'edit', 'body': {id: this.project.id}}
              const option = {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
              fetch('get_a_project.php', option)
              .then( res => res.json())
                  .then(data => console.log(data))
                  .catch(err => console.log(err.message))
          },
          toggleComplete() {
              // 0 == false  1 == true
              let temp_complete = 0;
              if (this.project.complete) temp_complete = 1;
              const body = {'action': 'update', 'body': {id: this.project.id, complete: temp_complete}}
              const option = {method: 'POST', mode: 'no-cors', headers: {'Content-Type': 'application/json'}, body: JSON.stringify(body)}
              fetch('update_complete_status.php', option)
                  .then(() => this.$emit('toggle_complete', this.project.id))
                  .catch(err => console.log(err.message))
  
          }
      }*/
};
</script>

<style scoped>
.grid_container_for_basic {
  display: grid;
  grid-template-columns: 12% auto 12% auto;
  grid-gap: 0px;
  background-color: lightblue;
  padding: 0px;
}

.subtitle {
  grid-column: 1 / span 6;
  grid-row: 1;
}
</style>
