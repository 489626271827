<template>
  <button class="btn info" @click="print">재료보충명세인쇄</button>

  <h2>가져간 재료 목록</h2>
  <div v-if="lines_to_print.length">
    <h3>재료보충명세서 {{ display_date(lines_to_print[0].date_used) }}</h3>
    <div v-for="record in lines_to_print" :key="record">
      <div class="row">
        <div class="column" style="margin:0px; padding:0px; height:26px;">
          <h7>&nbsp;&nbsp;{{ record.client }} &nbsp; {{ display_date(lines_to_print[0].date_used) }}</h7>
        </div>
      </div>
      <div v-for="line in record.can" :key="line">
        <div class="row">
          <div class="column1">
            <h6 style="margin:0px; padding:0px;" v-html="line"></h6>
          </div>
        </div>
      </div>
      <hr style="height:4px;margin:0px;padding:0px">
      <div v-for="line in record.coeffee" :key="line" style="color: blue">
        <div class="row">
          <div class="column1">
            <h6 style="margin:0px; padding:0px;" v-html="line"></h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import time_utils from "@/composables/utils/time_utils.js";
import array_utils from "@/composables/utils/array_utils.js";
import { my_storage } from "@/classes/my_storage.js";
import { ref, computed } from 'vue'

export default {
  props: ["lines_to_print"],
  components: {},
  setup(props, ctx) {
    const { display_date } = time_utils();
    const {
      sum_of_same_key_values,
      sum_products,
      negate_quantity,
      remove_zero_quan,
      group_amo_daedong,
      find_negative_quaned_products,
    } = array_utils();

    const lines_to_print = ref([]);

    const products_reception_records = computed(() =>
      my_storage.get("products_reception_records")
    );

    // 사무소에서 가져온 상품 기록중에 마킹된 기록
    const marked_products_reception_record = computed(() => {
      return products_reception_records.value.filter(
        (record) => record.check_field == "1"
      );
    });

    const products_records_to_print = computed(() => {
      return products_reception_records.value.filter(
        (record) =>
          record.date_used ==
          marked_products_reception_record.value[0].date_used
      );
    });

    function print() {
      // 전체, 평택단자, (아모텍+대동), 화성단소, 화성덴소로 분류한다.
      let data = group_amo_daedong(products_records_to_print.value);

      // 갯수가 -인 캔 종류 및 커피 종류를 array로 담아둔다.
      let negative_cans = find_negative_quaned_products(data, "can");
      let negative_coeffees = find_negative_quaned_products(data, "coeffee");

      //console.log('negative-cans')
      //console.log(negative_cans)
      //console.log(negative_coeffees)
      let i;
      lines_to_print.value = [];
      for (i = 0; i < data.length; i++) {
        lines_to_print.value.push({
          date_used: data[i].date_used,
          client: data[i].client,
          can: format_to_print(JSON.parse(data[i].can), negative_cans),
          coeffee: format_to_print(JSON.parse(data[i].coeffee), negative_coeffees),
        });
      }
    }

    function format_to_print(myarray, negative_products) {
      let i,
        count = 0,
        line = [],
        temp = "";
      for (i = 0; i < myarray.length; i++) {
        /*if (negative_products.indexOf(myarray[i].name) >= 0) {
          if (myarray[i].name != "")
            temp =
              temp +
              "<span style='color: red;'>" +
              myarray[i].name +
              " " +
              myarray[i].quan +
              ", </span>";
        } else {
          if (myarray[i].name != "")
            temp = temp + myarray[i].name + " " + myarray[i].quan + ", ";
        }*/
        if (myarray[i].quan<0) {
          if (myarray[i].name != "")
            temp =
              temp +
              "<span style='color: red; border: 2px solid red;border-radius: 5px;padding-left:2px; padding-right:2px;'>" +
              myarray[i].name +
              " " +
              myarray[i].quan +
              "</span>&nbsp;&nbsp;";
        } else if (myarray[i].quan>0) {
          if (myarray[i].name != "")
            temp = temp + myarray[i].name + " " + myarray[i].quan + "&nbsp;&nbsp;";
        }
        if (count == 4) {
          count = 0;
          line.push(temp);
          temp = "";
        } else {
          count++;
        }
      }
      if (temp != "") {
        line.push(temp);
      }
      return line;
    }

    return { display_date, print, lines_to_print };
  },
};
</script>
  
<style scoped>
.column {
  float: left;
  width: 100%;
  /*padding: 0px;
  margin:0px;*/
  /*height: 20px;*/ /* Should be removed. Only for demonstration */
  /*border-style: groove;*/
  background-color: black;
  color: white;
}
.column1 {
  float: left;
  width: 100%;
  /*padding: 0px;*/
  margin-left: 15px;
  /*height: 20px;*/ /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
p {
  padding: 1px;
  margin: 1px;
}
</style>
