const time_utils = () => {


    const display_date = (mydate) => {
      if (mydate=='none') return ""
      else return (
          "" +
          mydate.substr(0, 2) +
          "-" +
          mydate.substr(2, 2) +
          "-" +
          mydate.substr(4, 2) + " " + getDayOfWeek(mydate)
        );
      }
  
    const getDayOfWeek = (mydate) => {
        let mytemp =
          "20" +
          mydate.substr(0, 2) +
          "-" +
          mydate.substr(2, 2) +
          "-" +
          mydate.substr(4, 2);

        const d = new Date(mytemp);
        const day = d.getDay();
        //console.log('day')
        //console.log(day)
        let myreturn = "일요일";
        switch (day) {
          case 0:
            myreturn = "일요일";
            break;
          case 1:
            myreturn = "월요일";
            break;
          case 2:
            myreturn = "화요일";
            break;
          case 3:
            myreturn = "수요일";
            break;
          case 4:
            myreturn = "목요일";
            break;
          case 5:
            myreturn = "금요일";
            break;
          case 6:
            myreturn = "토요일";
            break;
          default:
            myreturn = "일요일";
        }
        return myreturn;
      }
  
    const current_date = () => {
        const date = new Date();
        let year, month, day
        year = '' + date.getFullYear()
        year = year.substr(2, 2)
        month = date.getMonth() + 1
        let smonth = ''
        if (month >= 0 && month <= 9) smonth = '0' + month
        else smonth = '' + month
        day = date.getDate()
        let sday = ''
        if (day >= 1 && day <= 9) sday = '0' + day
        else sday = '' + day

        return '' + year + smonth + sday
    }

    return { current_date, display_date }
}

export default time_utils
