<template>
  <!-- 거래처이름으로 서치 -->
  <div v-if="date_list.length">
    <span v-for="(date, index) in date_list" :key="index">
      <button @click="handler(date)">{{ date }}</button>
    </span>
  </div>
  <div v-if="data_formatted.length">
    <h2>카운타 - {{ display_date(date_selected) }}</h2>
    <div v-for="data in data_formatted" :key="data">
      <div class="row">
        <div class="column">
          <h3>{{ data.client }}</h3>
        </div>
      </div>
      <div v-for="machine in data.machines" :key="machine">
        <div class="row">
          <div class="column1">
            <h5 style="margin:0px; padding:2px;" v-html="machine.name"></h5>
          </div>
        </div>
        <div class="row">
          <div class="column1">
            <h5 style="margin:0px; padding:2px;margin-left:24px" v-html="machine.counter"></h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { my_storage } from "@/classes/my_storage.js";
import { ref, computed } from "vue";
import counter_utils from "@/composables/utils/counter_utils.js";
import time_utils from "@/composables/utils/time_utils.js";

export default {
  components: {},
  setup() {
    /*
    240101 240228 240331 
    1. 날짜를 추려서 버튼으로 한다.
    2. 날짜를 클릭하면
       평택단자
         1. 흡연실
            100)23 200)303 .....
            합).....
         2. 
    */
    /*
      [{
        client: '평택단자'
        machines: [
          {name: 기계1, counter:"2)300, 3)400, ....."},
          {name: 기계1, counter:"2)300, 3)400, ....."},
          {name: 기계1, counter:"2)300, 3)400, ....."},
          {name: 기계1, counter:"2)300, 3)400, ....."},
          {name: 기계1, counter:"2)300, 3)400, ....."},
                   ]
       }
      ]

      }]

    */

    const { display_date } = time_utils();

    const data_formatted = ref([]);
    const date_selected = ref("")

    const { counter_value } = counter_utils();

    const counters = computed(() => my_storage.get("counters"));
    const date_list = computed(() => {
      //console.log("counters in setup");
      //console.log(counters.value);
      var i,
        temp = [];
      for (i = 0; i < counters.value.length; i++) {
        temp.push(counters.value[i].counter_date);
      }
      //console.log(temp);
      let unique = [...new Set(temp)];
      //console.log("unique");
      //console.log(unique);
      return unique;
    });

    function handler(date) {
      date_selected.value = date
      var filteredData = counters.value.filter(
        (item) => item.counter_date == date
      );
      //console.log("filtereddata");
      //console.log(filteredData);
      var i,
        temp = [];
      for (i = 0; i < filteredData.length; i++) {
        temp.push(filteredData[i].client);
      }
      //console.log(temp);
      let unique = [...new Set(temp)];
      //console.log("unique clinet");
      //console.log(unique);

      let j,
        myarray = [],
        tempx;
      let newarray,
        tempobj = {},
        machines;
      for (i = 0; i < unique.length; i++) {
        tempobj = {};
        tempobj.client = unique[i];
        machines = [];
        newarray = filteredData.filter((item) => item.client == unique[i]);
        for (j = 0; j < newarray.length; j++) {
          //if (j == 0) console.log(newarray[j].client);
          tempx = printout(newarray[j]);
          machines.push(tempx);
        }
        tempobj.machines = machines;
        myarray.push(tempobj);
      }
      data_formatted.value = myarray;
      //console.log("data_formatted");
      //console.log(data_formatted.value);
    }

    function printout(myrecord) {
      let i,
        temp = "    ",
        temp2;
      temp2 = JSON.parse(myrecord.counter);
      //console.log(myrecord.name + " -> " + "total=" + temp2[temp2.length - 1]);

      //for (i = 0; i < temp2.length - 1; i++) {
      for (i = 0; i < 17; i++) {
        if (temp2[i] != 0)
          temp = temp + counter_value[i] / 100 + "=" + temp2[i] + ", ";
      }
      //console.log(temp);

      return {
        name: myrecord.name + " -> " + "total=" + temp2[temp2.length - 1],
        counter: temp,
      };
    }

    return {
      display_date,
      date_selected,
      data_formatted,
      counters,
      handler,
      date_list,
    };
  },
};
</script>

<style scoped>
.column {
  float: left;
  width: 100%;
  /*padding: 0px;
  margin:0px;*/
  /*height: 20px;*/
  /* Should be removed. Only for demonstration */
  border-style: groove;
  background-color: black;
  color: white;
}

.column1 {
  float: left;
  width: 100%;
  /*padding: 0px;*/
  margin-left: 15px;
  /*height: 20px;*/
  /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

h3 {
  padding: 1px;
  margin: 1px;
}
</style>
