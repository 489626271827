import { ref, } from 'vue';
class MyStorage {
  constructor() {

    this.can_entry_size = ref(26)
    this.coeffee_entry_size = ref(10)

    this.error = ref("")

    this.can_products = ref([])   // 캔 제품 목록
    this.coeffee_products = ref([]) // 커피 제품목록

    // id, name
    this.clients = ref([])

    // id, name, type, number, card_terminal, client, location, n_can_slots, n_coeffee_slots, can_slots, coeffee_slots
    this.machines = ref([])

    // id name (machine이름) client(거래처) counter_date(카운타적은날자)
    // counter [{"v":200,"q":0},{"v":300,"q":0},{"v":400,"q":0},{"v":500,"q":0},{"v":600,"q":0},{"v":700,"q":0},{"v":800,"q":0},{"v":900,"q":0},{"v":1000,"q":0},{"v":1100,"q":0},{"v":1200,"q":0},{"v":1300,"q":0},{"v":1400,"q":0},{"v":1500,"q":0},{"v":1600,"q":0},{"v":1700,"q":0},{"v":1800,"q":0},{"v":1900,"q":0},{"v":2000,"q":0}]
    this.counters = ref([])
    this.counters_calculated = ref([])

    // 깡통 (알) 단위의 재료 투입 기록 -- 사용일마다 계속 저장한다.
    // id, client, can_products, coeffee_products, date_used
    this.can_level_records = ref([])    // 알단위의 재료 투입기록

    // 알단위를 박스 단위로 환산한 기록  -- 사용일은 저장하지 않고... 계산시만 replace하여 사용
    // id, client, can_products, coeffee_products
    this.box_level_records = ref([])    // 박스 단위의 재료 투입기록 (임시)

    // 박스단위의 재료 투입기록... 
    // id, client=all/평택단자/화성단자.... date_used, check_field
    // calculatePt를 할때, 상기 임시 기록을 영구기록으로 저장한다. date_used로
    this.products_reception_records = ref([])   // 박스단위의 재료 투입기록 (영구)

    // 문서
    this.docs = ref([])


  }

  get(what) {
    switch (what) {
      case "can_entry_size": return this.can_entry_size.value
      case "coeffee_entry_size": return this.coeffee_entry_size.value
      case "clients": return this.clients.value
      case "machines": return this.machines.value
      case "can_products": return this.can_products.value
      case "coeffee_products": return this.coeffee_products.value
      case "box_level_records": return this.box_level_records.value
      case "can_level_records": return this.can_level_records.value
      case "marked_can_level_records": return this.can_level_records.value.filter((record) => record.check_field == '1')
      case "products_reception_records": return this.products_reception_records.value
      case "marked_products_reception_records": return this.marked_products_reception_records.value
      case "docs": return this.docs.value
      case "counters": return this.counters.value
      case "counters_calculated": return this.counters_calculated.value
    }
  }

  add(what, body) {
    switch (what) {
      case "clients": this.clients.value.push(body.params); break;
      case "machines": this.machines.value.push(body.params); break;
      case "can_products": this.can_products.value.push(body.params); break;
      case "coeffee_products": this.coeffee_products.value.push(body.params); break;
      case "box_level_records": this.box_level_records.value.push(body.params); break;
      case "can_level_records": this.can_level_records.value.push(body.params); break;
      case "products_reception_records": this.products_reception_records.value.push(body.params); break;
      case "docs": this.docs.value.push(body.params); break;
    }
  }

  delete(what, body) {
    switch (what) {
      case "clients": this.clients.value = this.clients.value.filter((item) => { return item.id != body.params.id }); break;
      case "machines": this.machines.value = this.machines.value.filter((item) => { return item.id != body.params.id }); break;
      case "can_products": this.can_products.value = this.can_products.value.filter((item) => { return item.id != body.params.id }); break;
      case "coeffee_products": this.coeffee_products.value = this.coeffee_products.value.filter((item) => { return item.id != body.params.id }); break;
      case "box_level_records": this.box_level_records.value = this.box_level_records.value.filter((item) => { return item.id != body.params.id }); break;
      case "can_level_records": this.can_level_records.value = this.can_level_records.value.filter((item) => { return item.id != body.params.id }); break;
      case "products_reception_records": this.products_reception_records.value = this.products_reception_records.value.filter((item) => { return item.id != body.params.id }); break;
      case "docs": this.docs.value = this.docs.value.filter((item) => { return item.id != body.params.id }); break;
    }
  }

  update(what, body) {
    let p
    switch (what) {
      case "can_products":
        p = this.can_products.value.find((product) => {
          return product.id === body.params.id;
        });
        p.name = body.params.name;
        break;
      case "coeffee_products":
        p = this.can_products.value.find((product) => {
          return product.id === body.params.id;
        });
        p.name = body.params.name;
        break;
      case "clients":
        p = this.clients.value.find((client) => {
          return client.id === body.params.id;
        });
        p.name = body.params.name;
        break;
      case "docs":
        p = this.docs.value.find((doc) => {
          return doc.id === body.params.id;
        });
        p.title = body.params.title
        p.contents = body.params.contents
        p.client = body.params.client
        break;
      case "machines":
        p = this.machines.value.find((machine) => {
          return machine.id === body.params.id;
        });
        //p = body.params
        p.name = body.params.name
        p.kind = body.params.kind
        p.client = body.params.client
        p.location = body.params.location
        p.n_can_slots = body.params.n_can_slots
        p.n_coeffee_slots = body.params.n_coeffee_slots
        p.coeffee_slots = body.params.coeffee_slots
        p.can_slots = body.params.can_slots
        p.machine_number = body.params.machine_number
        p.card_terminal_number = body.params.card_terminal_number
        p.counter = body.params.counter
        p.counter_date = body.params.counter_date
        break;
      case "can_level_records":
        p = this.can_level_records.value.find((record) => {
          return record.id === body.params.id;
        });
        //p = body.params
        p.date_used = body.params.date_used;
        p.client = body.params.client;
        p.can_products = body.params.can_products;
        p.coeffee_products = body.params.coeffee_products;
        break;

      case "box_level_records":
        p = this.box_level_records.value.find((record) => {
          return record.client === body.params.client;
        });
        p.can_products = body.params.can_products;
        p.coeffee_products = body.params.coeffee_products;
        break;

      case "products_reception_records":
        p = this.products_reception_records.value.find((record) => {
          return record.id === body.params.id;
        });
        p.can_products = body.params.can_products;
        p.coeffee_products = body.params.coeffee_products;
        break;


    }
  }

  mark(what, body) {
    let p
    switch (what) {
      case "can_level_records":
        p = this.can_level_records.value.find((record) => {
          return record.id === body.params.id;
        });
        p.check_field = body.params.check_field;
        break;

      case "products_reception_records":
        p = this.products_reception_records.value.find((record) => {
          return record.id === body.params.id;
        });
        p.check_field = body.params.check_field;
        break;
    }
  }

  reset(what) {
    switch (what) {
      case "can_level_records":
        this.can_level_records.value.forEach((record) => {
          record.check_field = "0";
        });
        break;
      case "box_level_records":
        this.box_level_records.value.forEach((record) => {
          record.can_products = '[{"name":"","quan":0}]'
          record.coeffee_products = '[{"name":"","quan":0}]'
        });
        break;
    }
  }

  async fetch_can_products(body) {
    try {
      const option = { method: 'POST', mode: 'no-cors', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

      //console.log('fetch can products')
      //console.log(body)
      let data = await fetch('api/can_products.php', option)
      if (!data.ok) {
        throw Error('no data available')
      }
      if (body.action == 'get') {
        this.can_products.value = await data.json()
        //console.log('can_products in js')
        //console.log(this.can_products.value)
      }
    }
    catch (err) {
      this.error.value = err.message
    }
  }
  async fetch_coeffee_products(body) {
    try {
      const option = { method: 'POST', mode: 'no-cors', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

      let data = await fetch('api/coeffee_products.php', option)
      if (!data.ok) {
        throw Error('no data available')
      }
      if (body.action == 'get') {
        this.coeffee_products.value = await data.json()
        //console.log('coeffee_products in js')
        //console.log(this.coeffee_products.value)
      }
    }
    catch (err) {
      this.error.value = err.message
    }
  }
  async fetch_clients(body) {
    try {
      const option = { method: 'POST', mode: 'no-cors', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

      let data = await fetch('api/clients.php', option)
      if (!data.ok) {
        throw Error('no data available')
      }
      if (body.action == 'get') {
        this.clients.value = await data.json()
        //console.log('clients in js')
        //console.log(this.clients.value)
      }
    }
    catch (err) {
      this.error.value = err.message
    }
  }
  async fetch_machines(body) {
    try {
      const option = { method: 'POST', mode: 'no-cors', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

      let data = await fetch('api/machines.php', option)
      if (!data.ok) {
        throw Error('no data available')
      }
      if (body.action == 'get') {
        this.machines.value = await data.json()
        //console.log('machines in js')
        //console.log(this.machines.value)
      }
    }
    catch (err) {
      this.error.value = err.message
    }
  }
  async fetch_can_level(body) {
    try {
      const option = { method: 'POST', mode: 'no-cors', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

      //console.log('message in fetch_can_level')
      //console.log(body)
      let data = await fetch('api/can_level.php', option)
      if (!data.ok) {
        throw Error('no data available')
      }
      if (body.action == 'get') {
        this.can_level_records.value = await data.json()
        //console.log('can_level_records in js')
        //console.log(this.can_level_records.value)
      }
    }
    catch (err) {
      this.error.value = err.message
    }
  }
  async fetch_box_level(body) {
    try {
      const option = { method: 'POST', mode: 'no-cors', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

      let data = await fetch('api/box_level.php', option)
      if (!data.ok) {
        throw Error('no data available')
      }
      if (body.action == 'get') {
        this.box_level_records.value = await data.json()
        //console.log('box_level_records in js')
        //console.log(this.box_level_records.value)
      }
    }
    catch (err) {
      this.error.value = err.message
    }
  }
  async fetch_products_reception(body) {
    try {
      const option = { method: 'POST', mode: 'no-cors', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

      let data = await fetch('api/products_reception.php', option)
      if (!data.ok) {
        throw Error('no data available')
      }
      if (body.action == 'get') {
        this.products_reception_records.value = await data.json()
        //console.log('products_reception_records in js')
        //console.log(this.products_reception_records.value)
      }
    }
    catch (err) {
      this.error.value = err.message
    }
  }
  async fetch_docs(body) {
    try {
      const option = { method: 'POST', mode: 'no-cors', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

      let data = await fetch('api/docs.php', option)
      //let data = await fetch('http://localhost:8080/api/docs.php', option)
      if (!data.ok) {
        throw Error('no data available')
      }
      if (body.action == 'get') {
        this.docs.value = await data.json()
        //console.log('docs in js')
        //console.log(this.docs.value)
      }
    }
    catch (err) {
      this.error.value = err.message
    }
  }

  async fetch_counters(body) {
    try {
      const option = { method: 'POST', mode: 'no-cors', headers: { 'Content-Type': 'application/json' }, body: JSON.stringify(body) }

      let data = await fetch('api/counters.php', option)
      if (!data.ok) {
        throw Error('no data available')
      }
      if (body.action == 'get') {
        this.counters.value = await data.json()
        //console.log('counters in js')
        //console.log(this.counters.value)
      }
      if (body.action == 'compute') {
        this.counters_calculated.value = await data.json()
        console.log('counters computed')
        console.log(this.counters_calculated.value)
      }
    }
    catch (err) {
      this.error.value = err.message
    }
  }


}
export let my_storage = new MyStorage();
