<template>
  <div class="my-w3-container">
    <div class="w3-display-container w3-green">
      <FilterNav mytitle="자판기관리" @filterChange="current = $event" :clients="clients" :current="current" />
    </div>
  </div>

  <button class="w3-button" @click="showAddMachineModal = true">
    새로운기계등록
  </button>

  <div v-if="error">{{ error }}</div>
  <div v-if="filteredMachines.length">
    <div v-for="machine in filteredMachines" :key="machine.name">
      <DisplayMachine :machine="machine" @message="message_handler" />
    </div>
  </div>
  <div v-else>Loading</div>

  <br />
  <!-- 새로운 기계 생성
        basic info 와 slot info를 구분한다.
        기계 생성의 경우 basic info만 입력한다.
  -->
  <!--  mode='add' for adding new machine
         mode='edit' for editing a machine
  -->
  <div v-if="showAddMachineModal">
    <BasicInfoModal mode="add" :clients="clients" @message="message_handler" @close="showAddMachineModal = false">
    </BasicInfoModal>
  </div>
</template>

<script>
//import { onMounted } from 'vue'
// @ is an alias to /src
import DisplayMachine from "@/views/machine_management/display_machine/DisplayMachine.vue";
import BasicInfoModal from "@/views/machine_management/modals/BasicInfoModal.vue";
import FilterNav from "@/components/FilterNav.vue";

import { my_storage } from "@/classes/my_storage.js";
import { computed } from "vue";
import { ref } from "vue";
export default {
  name: "MachineManagement",
  components: { DisplayMachine, FilterNav, BasicInfoModal },
  setup() {
    const current = ref("all");

    const clients = computed(() => my_storage.get("clients"));
    const machines = computed(() => my_storage.get("machines"));
    const can_products = computed(() => my_storage.get("can_products"));
    const coeffee_products = computed(() => my_storage.get("coeffee_products"));

    // flag for open modal for adding new machine
    const showAddMachineModal = ref(false);

    const filteredMachines = computed(() => {
      if (current.value == "all") {
        return machines.value;
      }
      return machines.value.filter(
        (machine) => machine.client == current.value
      );
    });

    function message_handler(message) {
      console.log("message in machine management.vue");
      console.log(message);
      my_storage.fetch_machines(message);

      switch (message.action) {
        case "update_counter":
          my_storage.fetch_counters(message); // to create on in table counter
          // this is for counter
          // 아래의 update는 basicInfo, SlotInfo용
          my_storage.update("machines", message); // this is OK 
          break;

        case "update":
          my_storage.update("machines", message);
          break;

        case "delete":
          my_storage.delete("machines", message);
          break;

        case "add":
          my_storage.add("machines", message);
          break;
      }
    }

    return {
      current, // 현재 선택된 거래처 이름

      machines, // 기계 목록
      filteredMachines, // 거래처이름으로 선택된 기계 목록

      clients, // 거래처이름 목록

      can_products,
      coeffee_products,

      showAddMachineModal,

      message_handler,
    };
  },
};
</script>

<style>
/*.filter-nav button {
  background: none;
  border: none;
  color: #bbb;
  outline: none;
  font-size: 12px;
  margin-right: 10px;
  letter-spacing: 1px;
  font-weight: bold;
  cursor: pointer;
}*/
</style>