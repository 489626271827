<template>
  <div class="my-w3-container w3-border">
    <div class="w3-cell-row">
      <button class="w3-button w3-cel w3-right" @click="showEditDocModal = true">
        수정하기
      </button>
      <button class="w3-button w3-cell w3-right" @click="deleteHandler">삭제하기</button>
    </div>

    <!--<div class="items_on_the_same_line machine_details_subtitle">
      <h3>contents</h3>
      <button class="btn success" @click="showEditDocModal = true">
        문서수정하기
      </button>
      <button class="btn success" @click="deleteHandler">
        문서삭제하기
      </button>
    </div>-->
    <div class="doc_contents mySpanStyle">
      <p>{{ doc.contents }}</p>
    </div>
  </div>

  <div v-if="showEditDocModal">
    <!--doc은 add의 용도에서는 더미로 패스함.-->
    <DocModal mode="update" :doc="doc" :clients="clients" @update="update" @close="showEditDocModal = false">
      <template v-slot:modal_title>
        <h2>문서 수정하기</h2>
      </template>

      <template v-slot:submit_button>
        <button class="btn warning">문서 수정하기</button>
      </template>
    </DocModal>
  </div>
</template>

<script>
import { ref } from "vue";
import DocModal from "@/views/doc_management/modals/DocModal.vue";
//import machine_utils from '@/composables/machine/machine_utils.js'

export default {
  props: ["doc", "clients"],
  components: {
    DocModal,
  },
  setup(props, ctx) {
    const showEditDocModal = ref(false);

    function update(updated_doc) {
      //machine_db_api( {'action': 'update_basic_info', 'body': { new_basic_info }})
      //console.log("updated_doc in displaycontents.vue");
      //console.log(updated_doc);
      ctx.emit("update", updated_doc);
    }

    function deleteHandler() {

      if (confirm('문서를 삭제하겠습니까?')) {
        let delete_info = {
          action: "delete",
          params: {
            id: props.doc.id,
          }
        }
        ctx.emit("update", delete_info);
        ctx.emit('close')
      } else {
        alert('문서 삭제가 취소되었습니다.')
      }
    }


    return {
      showEditDocModal,

      update,
      deleteHandler
    };
  },
};
</script>

<style scoped>
.grid_container_for_basic {
  display: grid;
  grid-template-columns: 12% auto 12% auto 12% auto;
  grid-gap: 1px;
  background-color: lightblue;
  padding: 1px;
}

.subtitle {
  grid-column: 1 / span 6;
  grid-row: 1;
}

.doc_contents {
  border-radius: 15px;
  background-color: rgb(250, 249, 193);
}

.mySpanStyle {
  color: black;
  white-space: pre;
  font-weight: normal;
}
</style>